import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Grid from '@mui/material/Grid';

export interface CreateLayoutProps {
  onClose?: () => void;
  title: React.ReactNode;
  form: React.ReactNode;
  actions: React.ReactNode;
}

export const CreateLayout: React.FC<CreateLayoutProps> = ({
  onClose,
  title,
  form,
  actions,
}) => {
  return (
    <Dialog
      fullScreen
      open={true}
    >
      {
        onClose &&
          (
            <Box
              sx={{ m: 1 }}
            >
              <IconButton
                size='large'
                onClick={onClose}
              >
                <CloseIcon fontSize='large' />
              </IconButton>
            </Box>
          )
      }
      <Box
        sx={{
          mb: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          direction="row"
        >
          <Grid container item xs={12}>
            <Grid item xs></Grid>
            <Grid container direction="column" spacing={5} item xs={10} lg={3} md={5} sm={8}>
              <Grid item xs>
                { title }
              </Grid>
              <Grid item xs>
                { form }
              </Grid>
              <Grid item xs>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  { actions }
                </Box>
              </Grid>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
