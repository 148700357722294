import { auth, db } from "../../../../firebase";
import { DogCardData } from "../card/services/publicCardDTO";
import { ListDogCardContext } from "../machines/listCards";

export const listPublicCardsService = ({ dogId }: ListDogCardContext, event: any): Promise<DogCardData[]> => new Promise((resolve, reject) => {
  const userId = auth.currentUser?.uid;
  const cards: DogCardData[] = [];
  db.collection(`users/${userId}/dogs/${dogId}/publicCards`)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const card: any = doc.data();
        cards.push({
          id: doc.id,
          ...card,
        });
      });
      resolve(cards);
    })
    .catch((error) => {
      reject(error);
    });
});