
interface DogsReference {
  get: (key: string) => any;
  set: (id: string, dog: any) => void;
}

export enum CardAccess {
  Public,
  Private
}
interface CardsReference {
  get: (key: string, acces: CardAccess) => any;
  set: (id: string, acces: CardAccess, card: any) => void;
}

class Store {
  state: { [key in string]: any } = {};

  commit (key: string, value: any) {
    this.state[key] = value
  }

  get (key: string) {
    return this.state[key];
  }
  
  public get dogsService(): DogsReference {
    return {
      get: (id: string) => this.get(`dogs-service-${id}`),
      set: (id: string, dog: any) => this.commit(`dogs-service-${id}`, dog),
    }
  }
  public get dogsContext(): DogsReference {
    return {
      get: (id: string) => this.get(`dogs-context-${id}`),
      set: (id: string, dog: any) => this.commit(`dogs-context-${id}`, dog),
    }
  }

  public get cardsService(): CardsReference {
    return {
      get: (id: string, access: CardAccess) => this.get(`${access}-cards-service-${id}`),
      set: (id: string, access: CardAccess, card: any) => this.commit(`${access}-cards-service-${id}`, card),
    }
  }
  public get cardsContext(): CardsReference {
    return {
      get: (id: string, access: CardAccess) => this.get(`${access}-cards-context-${id}`),
      set: (id: string, access: CardAccess, card: any) => this.commit(`${access}-cards-context-${id}`, card),
    }
  }
}

export const store = new Store();
