import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ImageIcon from '@mui/icons-material/Image';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, useTheme } from '@mui/material';

export interface DogProfilePictureProps {
  url: string | null;
  isLoading: boolean;
  onFileChange: (file: File) => void;
  onFileDelete: () => void;
}

export const DogProfilePicture: React.FC<DogProfilePictureProps> = ({
  url,
  isLoading,
  onFileChange,
  onFileDelete,
}) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const [confirmDelete, setConfirmDelete] = useState(false);

  let size = 0;
  let fontSize = '';
  if (isLarge) {
    size = 400;
    fontSize = '8rem';
  } else {
    size = 200;
    fontSize = '5rem';
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      onFileChange(file);
    }
  }

  return (
    <Box>
      <Box
        sx={{
          width: size,
          height: size,
          mb: 1,
        }}
      >
        {
          url ?
            (<img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: theme.shape.borderRadius,
              }}
              src={url}
              loading="lazy"
            />) : 
            <Paper
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[100],
              }}
              elevation={0}
            >
              <ImageIcon
                color='secondary'
                sx={{
                  fontSize,
                }}
              />
            </Paper>
        }
        { isLoading ? <LinearProgress sx={{ mt: url ? 0 : 1 }} /> : null }
      </Box>
      <Box>
        <label htmlFor="contained-button-file">
          <input
            style={{ display: 'none' }}
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={ handleFileChange }
          />
          <Button variant="text" component="span">
            { url ? 'Update Image' : 'Upload Image' }
          </Button>
        </label>
        {
          url
            ? (
              <Button
                color="error"
                variant="text"
                component="span"
                onClick={() => setConfirmDelete(true)}
              >
                Delete Image
              </Button>
            ) : null
        }
      </Box>

      <Dialog
        open={ confirmDelete }
        aria-labelledby="alert-dialog-delete-profile-picture"
        aria-describedby="alert-dialog-delete-profile-picture-description"
      >
        <DialogTitle id="alert-dialog-delete-profile-picture">
          Delete profile picture?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-delete-profile-picture-description">
            This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setConfirmDelete(false) }>Cancel</Button>
          <Button onClick={
            () => {
              onFileDelete()
              setConfirmDelete(false)
            }
          }>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
