import React, { useState } from 'react';

import { Routes, Route, useLocation, matchPath } from "react-router-dom";

import Box from '@mui/material/Box';

import { isMobile } from '../../../utils/mobile';
import { AppDrawer } from './appbar/drawer';
import { AppBaseToolbar } from './appbar/baseToolbar';
import { DogToolbar } from './appbar/dogToolbar';
import { CardsToolbar } from './appbar/cardsToolbar';
import { CardToolbar } from './appbar/cardToolbar';

export const AppWrapper:  React.FC = ({ children }) => {
  const location = useLocation();
  const [drawerOpen, setDrawerState] = useState(false);
  
  const mobile = isMobile();

  const handleToggleDrawer = () => setDrawerState(!drawerOpen);

  const appDrawerProps = {
    open: drawerOpen,
    handleSetOpen: (open: boolean) => setDrawerState(open),
  };

  const tabsRoutesWhiteList = [
    '/dogs/:dogId/*',
  ];
  const tabsRoutesBlackList = [
    '/dogs/:dogId/cards/public/:cardId'
  ];
  const hasTabs = tabsRoutesWhiteList.some(r => matchPath(r, location.pathname))
    && tabsRoutesBlackList.every(r => !matchPath(r, location.pathname));
  let appMt = null
  if (mobile) {
    appMt = hasTabs ? '104px' : '54px'
  } else {
    appMt = hasTabs ? '114px' : '64px'
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Routes>
        <Route
          path="/dogs/:dogId/cards/new"
          element={ <AppBaseToolbar title="Dogs" handleToggleDrawer={ handleToggleDrawer } /> }
        />
        <Route
          path="/dogs/:dogId/cards/public/:cardId"
          element={ <CardToolbar /> }
        />
        <Route
          path="/dogs/:dogId/cards/*"
          element={ <CardsToolbar /> }
        />
        <Route
          path="/dogs/new"
          element={ <AppBaseToolbar title="Dogs" handleToggleDrawer={ handleToggleDrawer } /> }
        />
        <Route
          path="/dogs/:dogId/*"
          element={ <DogToolbar /> }
        />
        <Route
          path="/dogs"
          element={ <AppBaseToolbar title="Dogs" handleToggleDrawer={ handleToggleDrawer } /> }
        />
        <Route
          path="/settings"
          element={ <AppBaseToolbar title="Settings" handleToggleDrawer={ handleToggleDrawer } /> }
        />
      </Routes>
      <AppDrawer { ...appDrawerProps } />
      <Box
        sx={{
          flexGrow: 1,
          mt: appMt,
        }}
      >
        { children }
      </Box>
    </Box>
  );
};
