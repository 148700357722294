import { auth, db } from "../../../../firebase";
import { EditDogContext } from "../machines/editDog";
import { deleteDogProfilePicture } from "./deleteDogProfilePicture";

export const deleteDogService = ({ id }: EditDogContext, event: any): Promise<void> =>
  new Promise((resolve, reject) => {
    const userId = auth.currentUser?.uid;

    if (!userId) {
      throw new Error('No user infor');
    }

    db.collection(`users/${userId}/dogs`).doc(id)
      .delete()
      .then(() => {
        deleteDogProfilePicture({ id }, event);
        resolve();
      })
      .catch(reject);
  });
