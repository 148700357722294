import React from 'react';
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { dogRoutes } from '../dogs/router';
import { authenticationRoutes } from '../authentication/router';
import { settingsRoutes } from '../settings/router';
import { cardsRoutes } from '../cards/router';
import { AppWrapper } from './components/appWrapper';

export interface RouterProps {
  authenticated: boolean;
}

export const Router = ({ authenticated }: RouterProps) => {
  return (
    authenticated
      ? (<AppWrapper>
          <Routes>
            { cardsRoutes() }
            { dogRoutes() }
            { settingsRoutes() }
            <Route path="/*" element={ <Navigate to="/dogs" /> }/>
          </Routes>
        </AppWrapper>)
      : (<Routes>
          { cardsRoutes() }
          { authenticationRoutes() }
          <Route path="/*" element={ <Navigate to="/login" /> }/>
        </Routes>)
  )
}
