import React, { useEffect, useState } from 'react';

import { useNavigate, Navigate, useParams } from "react-router-dom";
import { DateTime } from 'luxon';
import { useMachine } from '@xstate/react';

import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { createCreateDogCardMachine } from '../machines/createCard';
import { CreateLayout } from './components/creation-layout';
import { DogCardPersonalInformationConfigurationForm, DogCardPersonalInformationConfigurationFormProps, SetPersonalProps } from '../forms/dogPersonalInformation';
import { DogCardPersonalContext, DogCardPersonalKey } from '../machines/contexts/configuration';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { PublicDogCardConfigurationForm, PublicDogCardConfigurationFormProps } from '../forms/publicDogCard';

// import { DogInformationForm, DogInformationFormProps } from '../forms/dogInformation';
// import { DogSex, Weight } from '../enums';
// import { CreateLayout } from './components/creation-layout';
// import { createDogMachine } from '../machines/createDog';

enum AccessLevel {
  Public = 'Public',
  Private = 'Private'
}

export const CreateDogCard: React.FC = () => {
  const navigate = useNavigate();
  const { dogId } = useParams();

  const [ state, send ] = useMachine(() => createCreateDogCardMachine({ dogId: dogId || '' }));

  // const dogInfoProps: DogInformationFormProps = {
  //   onNameChange: (name: string) => send('INPUT_NAME', { name }),
  //   onBreedChange: (breed: string) => send('INPUT_BREED', { breed }),
  //   onDateOfBirthChange: (dateOfBirth: DateTime | null) => send('INPUT_DATE_OF_BIRTH', { dateOfBirth }),
  //   onSexChange: (sex: DogSex) => send('INPUT_SEX', { sex }),
  //   onWeightValueChange: (value: number) => send('INPUT_WEIGHT', { value }),
  //   onWeightUnitChange: (unit: Weight) => send('INPUT_WEIGHT_UNIT', { unit }),
  //   onMicrochippedChange: (microchipped: boolean) => send('INPUT_MICROCHIPPED', { microchipped }),
  //   onDefiningMarkingsChange: (definingMarkings: string) => send('INPUT_DEFINING_MARKINGS', { definingMarkings }),
  //   ...state.context,
  // };

  const handleClose = () => navigate('/dogs');
  const handleCreate = () => {
    if (state.matches('creating')) return;
    send('CREATE')
  }

  const dogCardPersonalInfoProps: DogCardPersonalInformationConfigurationFormProps = {
    personal: state.context.personal,
    onSetAll: (checked: boolean) => send(
      Object.keys(state.context.personal).map(k => ({
        type: 'INPUT_PERSONAL',
        key: k as DogCardPersonalKey,
        checked,
      }))
    ),
    onSetPersonal: (props: SetPersonalProps) => send('INPUT_PERSONAL', props),
  };

  const publicDogCardInfoProps: PublicDogCardConfigurationFormProps = {
    ...dogCardPersonalInfoProps,
    name: state.context.name,
    onSetName: (name: string) => send('INPUT_NAME', { name })
  };

  let accessLevel = AccessLevel.Public; 
  if (state.matches('private')) {
    accessLevel = AccessLevel.Private;
  }
  const handleChangeAccessLevel = (accessLevel: AccessLevel) => {
    if (accessLevel === AccessLevel.Public) {
      send('SET_PUBLIC_ACCESS');
    } else if (accessLevel === AccessLevel.Private) {
      send('SET_PRIVATE_ACCESS');
    }
  }

  useEffect(() => {
    if (state.matches('created')) {
      navigate(`/dogs/${dogId}/cards/public/${state.context.id}`, { replace: true });
    }
  }, [state.value]);

  return (
    // state.matches('created')
    //   ? <Navigate to="/dogs" />
      // : <CreateLayout
      //     onClose={handleClose}
      //     title={
      //       <Typography textAlign="center" variant='h4'>Dog Information</Typography>
      //     }
      //     form={
      //       <DogInformationForm {...dogInfoProps} />
      //     }
      //     actions={
      //       <LoadingButton
      //         variant="contained"
      //         onClick={ handleCreate }
      //         loading={ state.matches('creating') }
      //         disabled={ state.matches('idle') }
      //       >Create</LoadingButton>
      //     }
      //   />
      <CreateLayout
        onClose={handleClose}
        title={
          <Typography textAlign="center" variant='h4'>Dog Card</Typography>
        }
        form={
          <Box>
            <FormControl sx={{ minWidth: 150, mb: 2 }}>
              <InputLabel id="label-access">Access Level</InputLabel>
              <Select
                labelId="label-access"
                id="access"
                label="Access Level"
                value={accessLevel}
                onChange={(e) => handleChangeAccessLevel(e.target.value as AccessLevel)}
              >
                <MenuItem value={ AccessLevel.Public }>{ AccessLevel.Public }</MenuItem>
                {/* <MenuItem value={ AccessLevel.Private }>{ AccessLevel.Private }</MenuItem> */}
              </Select>
            </FormControl>

            {
              accessLevel === AccessLevel.Public
                ? (<PublicDogCardConfigurationForm { ...publicDogCardInfoProps } />)
                : null
            }
          </Box>
        }
        actions={
          <LoadingButton
            variant="contained"
            onClick={ handleCreate }
            loading={ state.matches('creating') }
            disabled={ state.matches('setup') }
          >Create</LoadingButton>
        }
      />
  );
}
