import { auth, storage } from "../../../../firebase";
import { DogBaseContext } from '../machines/contexts/base';
import { FileContext } from "../machines/contexts/file";
import { ImageContext } from '../machines/contexts/image';

type DeleteDogMedicalHistoryDocumentsContext = DogBaseContext & { medicalHistoryDocuments: FileContext[] };

export const deleteDogMedicalHistoryDocument = ({ id, medicalHistoryDocuments }: DeleteDogMedicalHistoryDocumentsContext, { ref }: { ref: string }): Promise<FileContext[]> =>
  new Promise((resolve, reject) => {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(ref);
    fileRef.delete()
      .then(() => {
        resolve(medicalHistoryDocuments.filter(od => od.ref && od.ref !== ref));
      })
      .catch(reject);
  });
