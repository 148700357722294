import firebase from 'firebase/compat/app';
import { db } from "../../../../../firebase";
import { DogCardBaseContext, DogCardCheckContext, DogCardEntitiesContext } from "../machines/contexts/base";
import { DogCardPersonalContext } from '../machines/contexts/configuration';

type CreateShareUrlProps = { id: string };
export const createShareUrl = ({ id }: CreateShareUrlProps) => {
  const domain = window.location.host;
  const cardsPath = 'c';
  return `${domain}/${cardsPath}/${id}`;
}

type DogCardPersonal = {
  personal: DogCardPersonalContext,
  name: string,
};

type CreateDogCardProps =
  & DogCardBaseContext
  & DogCardEntitiesContext
  & DogCardPersonal;

type DogCardResult = {
  url: string;
  id: string;
};

export const updateDogCardService = (context: CreateDogCardProps, event: any): Promise<DogCardResult> =>
  new Promise((resolve, reject) => {
    const {
      dogId,
      userId,
      id,
    } = context;

    console.log(id)

    if (!id)  throw new Error('No id');
    else if (!userId) throw new Error('No user id');
    else if (!dogId) throw new Error('No dog id');

    const batch = db.batch();

    const cardRef = db.collection('publicCards').doc(id);
    const cardProps = {
      ...context,
      updatedAt: firebase.firestore.Timestamp.now(),
    };

    delete cardProps.createdAt;
    
    batch.update(cardRef, cardProps);

    const userCardRef = db.collection(`users/${userId}/dogs/${dogId}/publicCards`).doc(cardRef.id);
    batch.update(userCardRef, cardProps);

    batch.commit()
      .then(() => {
        const url = createShareUrl({
          id: cardRef.id,
        });
        console.log(url);
        resolve({
          url,
          id: cardRef.id,
        });
      })
      .catch((e) => {
        console.log(e)
        reject(e)
      });
  });

type CreateDogCardPublicProps = CreateDogCardProps;
export const updateDogCardPublicService = (context: CreateDogCardPublicProps, event: any): Promise<DogCardResult> =>
  updateDogCardService(context, event);
