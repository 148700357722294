import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { EditDogLayout } from './components/layout';
import { VeterinarianInformationForm, VeterinarianInformationFormProps } from '../forms/veterinaryInformation';
import { useParams } from 'react-router-dom';
import { EditDogActor } from '../machines/editDog';
import { store } from '../../../core/store';
import { useActor } from '@xstate/react';

export const EditDogVeterinarianInformation: React.FC = () => {
  const params = useParams();

  const service: EditDogActor = store.dogsService.get(params.dogId ?? '');
  const [state] = useActor(service);
  const send = service.send;

  const veterinarian = state.context.veterinarian;

  const veterinarianInfoFormProps: VeterinarianInformationFormProps = {
    onNameChange: (name: string) => send('INPUT_VETERINARIAN_NAME', { name }),
    onWebsiteChange: (website: string) => send('INPUT_VETERINARIAN_WEBSITE', { website }),
    onPhoneNumberChange: (phoneNumber: string) => send('INPUT_VETERINARIAN_PHONE_NUMBER', { phoneNumber }),
    onEmailChange: (email: string) => send('INPUT_VETERINARIAN_EMAIL', { email }),
    onAddress1Change: (address1: string) => send('INPUT_VETERINARIAN_ADDRESS_1', { address1 }),
    onAddress2Change: (address2: string) => send('INPUT_VETERINARIAN_ADDRESS_2', { address2 }),
    onCityChange: (city: string) => send('INPUT_VETERINARIAN_CITY', { city }),
    onStateChange: (state: string) => send('INPUT_VETERINARIAN_STATE', { state }),
    onZipCodeChange: (zipCode: string) => send('INPUT_VETERINARIAN_ZIP_CODE', { zipCode }),
    ...veterinarian,
  };

  return (
    <EditDogLayout>
      <Grid
        container
        spacing={4}
      >
        <Grid item lg={6} md>
          <Grid item sx={{ mb: 4 }}>
            <Typography variant='h4'>Veterinarian Information</Typography>
          </Grid>
          <VeterinarianInformationForm { ...veterinarianInfoFormProps } />
        </Grid>
      </Grid>
    </EditDogLayout>
  );
}
