export const createDogCardPersonalContext = (): DogCardPersonalContext => ({
  name: true,
  breed: true,
  dateOfBirth: false,
  sex: true,
  weight: false,
  microchipped: true,
  definingMarkings: true,
  careInformation: true,
});

export enum DogCardPersonalKey {
  name = 'name',
  breed = 'breed',
  dateOfBirth = 'dateOfBirth',
  sex = 'sex',
  weight = 'weight',
  microchipped = 'microchipped',
  definingMarkings = 'definingMarkings',
  careInformation = 'careInformation',
}

export interface DogCardPersonalContext {
  [DogCardPersonalKey.name]: boolean;
  [DogCardPersonalKey.breed]: boolean;
  [DogCardPersonalKey.dateOfBirth]: boolean;
  [DogCardPersonalKey.sex]: boolean;
  [DogCardPersonalKey.weight]: boolean,
  [DogCardPersonalKey.microchipped]: boolean;
  [DogCardPersonalKey.definingMarkings]: boolean;
  [DogCardPersonalKey.careInformation]: boolean;
}

export const DogCardPersonalOrdering: [DogCardPersonalKey, string][] = [
  [DogCardPersonalKey.name, 'Name'],
  [DogCardPersonalKey.breed, 'Breed'],
  [DogCardPersonalKey.dateOfBirth, 'Date of Birth'],
  [DogCardPersonalKey.sex, 'Sex'],
  [DogCardPersonalKey.weight, 'Weight'],
  [DogCardPersonalKey.microchipped, 'Microchipped'],
  [DogCardPersonalKey.definingMarkings, 'Defining Markings'],
  [DogCardPersonalKey.careInformation, 'Care Information'],
];
