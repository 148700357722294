export  const createFileContext = (ref: string | undefined): FileContext => ({
  ref,
  name: undefined,
  url: null
});

export interface FileContext {
  ref: string | undefined;
  name: string | undefined;
  url: string | null
}
