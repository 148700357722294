import firebase from 'firebase/compat/app';
import { Timestamp } from "firebase/firestore";

import { auth, db } from "../../../../firebase";
import { EditDogContext } from '../machines/editDog';
import { DogData } from './dogDTO';

export const updateDogService = (context: EditDogContext, event: any): Promise<void> => new Promise((resolve, reject) => {
  const userId = auth.currentUser?.uid;
  const dogId = context.id;

  if (!userId || !dogId) {
    throw new Error('user or dog unknown.');
  }

  const dogData: DogData = Object.assign(
    { ...context },
    { profilePicture: context.profilePicture.ref ?? '' },
    { ownerDocuments: context.ownerDocuments.map( od => od.ref) },
    { medicalHistoryDocuments: context.medicalHistoryDocuments.map( od => od.ref) },
    { dateOfBirth: context.dateOfBirth && Timestamp.fromDate(context.dateOfBirth.toJSDate()), },
    { updatedAt: firebase.firestore.Timestamp.now() },
  );

  db.collection(`users/${userId}/dogs`)
    .doc(dogId)
    .update(dogData)
    .then(resolve)
    .catch((e) => {
      console.log(e)
      reject()
    });
});
