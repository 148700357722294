import React, { useState } from 'react';

import { useActor } from '@xstate/react';

import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import StyleIcon from '@mui/icons-material/Style';
import SaveIcon from '@mui/icons-material/Save';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { CardAccess, store } from '../../store';
import { EditDogActor } from '../../../dogs/dog/machines/editDog';
import { BaseAppBar } from './baseAppBar';
import Button from '@mui/material/Button';
import { EditPublicDogCardActor } from '../../../dogs/cards/card/machines/editPublicCard';

type TabConfig = {
  route: string;
  name: string;
};

export const CardToolbar: React.FC = () => {
  const navigate = useNavigate();
  const { cardId, dogId } = useParams();
  const location = useLocation();
  const access = location.pathname.match(/public/) ? CardAccess.Public : CardAccess.Private;

  const dogService: EditDogActor = store.dogsService.get(dogId ?? '');
  const [dogState] = useActor(dogService);
  const dogName = dogState.context.name;

  const cardService: EditPublicDogCardActor = store.cardsService.get(cardId ?? '', access);
  const [cardState] = useActor(cardService);
  const send = cardService.send;

  const { id, name } = cardState.context;

  const isEditing = ['editing', 'updating'].some(cardState.matches);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClickActionMore = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const actionMenuControl = (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <IconButton
        size="large"
        aria-label="display more actions"
        edge="end"
        color="inherit"
        onClick={handleClickActionMore}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseActionMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => send('DELETE')}>Delete</MenuItem>
      </Menu>
    </Box>
  );

  const viewToolbar = (
    <Box>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => navigate(-1)}
          edge="start"
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          { dogName } / { name || id }
        </Typography>
        { actionMenuControl }
      </Toolbar>
    </Box>
  );

  const editToolbar = (
    <Toolbar>
      <Typography variant="h6" noWrap component="div">
        Unsaved Changes
      </Typography>
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
        <IconButton
          sx={{
            mr: 2,
          }}
          color="inherit"
          aria-label="discard"
          onClick={() => send('DISCARD_CHANGES')}
          edge="start"
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="save"
          onClick={() => send('UPDATE')}
          edge="start"
        >
          <SaveIcon />
        </IconButton>
      </Box>
    </Toolbar>
  );

  return (
    <BaseAppBar color={isEditing ? 'secondary' : 'primary'}>
      {
        isEditing
        ? editToolbar
        : viewToolbar
      }
    </BaseAppBar>
  );
};
