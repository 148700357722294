import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

export interface DogMultiFileInputFormProps {
  files: { name?: string, id?: string, url: string | null }[];
  onFileAdd: (file: File) => void;
  onFileDelete: (id: string) => void;
  uploadingFile: boolean;
  deletingFile: boolean;
  addText?: string;
  removeText?: string;
}

export const DogMultiFileInputForm: React.FC<DogMultiFileInputFormProps> = ({
  files,
  onFileAdd,
  onFileDelete,
  uploadingFile,
  deletingFile,
  addText,
  removeText,
}) => {
  const [key, setKey] = useState(0);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      onFileAdd(file);
      setKey(key + 1);
    }
  }

  const handleFileDelete = (id?: string) => {
    if (id) {
      onFileDelete(id)
    }
  }

  const handleOpenFile = (url: string | null) => {
    if (url) {
      window.open(url);
    }
  }

  return (
    <Grid
      container
      spacing={2}
    >
      {
        files.map(file => (
          <Grid key={file.id} item xs={12}>
            <Button variant="text" component="span" onClick={() => handleOpenFile(file.url)}>
              { file.name }
            </Button>
            <LoadingButton
              variant="text"
              component="span"
              onClick={() => handleFileDelete(file.id)}
              loading={ deletingFile }
              disabled={ deletingFile }
            >
              { removeText ?? 'Delete' }
            </LoadingButton>
          </Grid>
        ))
      }
      <Grid item xs={12}>
        <label htmlFor="contained-button-file">
          <input
            key={key}
            style={{ display: 'none' }}
            accept=".pdf,.doc,image/*"
            id="contained-button-file"
            type="file"
            onChange={ handleFileChange }
          />
          <LoadingButton
            variant="text"
            component="span"
            loading={ uploadingFile }
            disabled={ uploadingFile }
          >
            { addText ?? 'Add' }
          </LoadingButton>
        </label>
      </Grid>
    </Grid>
  );
}
