import React from 'react';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { ManualAuthForm, AuthTypes } from '../common/manual-auth-form';

export const Signup = () => {

  const signupWithEmailAndPassword = (email: string, password: string) => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
  }

  return (
    <ManualAuthForm type={AuthTypes.Signup} />
  );
}
