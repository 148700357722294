import { DateTime } from "luxon";
import { DogSex, Weight } from "../../enums";

export const createDogPersonalInformationContext = (): DogPersonalInformationContext => ({
  name: '',
  breed: '',
  dateOfBirth: null,
  sex: DogSex.None,
  weight: {
    value: 0,
    unit: Weight.Pounds,
  },
  microchipped: false,
  definingMarkings: '',
  careInformation: '',
});

export interface DogPersonalInformationContext {
  name: string;
  breed: string;
  dateOfBirth: DateTime | null;
  sex: DogSex;
  weight: {
    value: number;
    unit: Weight;
  },
  microchipped: boolean;
  definingMarkings: string;
  careInformation: string;
}
