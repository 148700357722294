import React from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export interface ContactInformationFormProps {
  firstName: string;
  onFirstNameChange: (firstName: string) => void;
  lastName: string;
  onLastNameChange: (lastName: string) => void;
  phoneNumber: string;
  onPhoneNumberChange: (phoneNumber: string) => void;
  email: string;
  onEmailChange: (email: string) => void;
  address1: string;
  onAddress1Change: (address1: string) => void;
  address2: string;
  onAddress2Change: (address2: string) => void;
  city: string;
  onCityChange: (city: string) => void;
  state: string;
  onStateChange: (state: string) => void;
  zipCode: string;
  onZipCodeChange: (zipCode: string) => void;
}

export const ContactInformationForm: React.FC<ContactInformationFormProps> = ({
  firstName,
  onFirstNameChange,
  lastName,
  onLastNameChange,
  phoneNumber,
  onPhoneNumberChange,
  email,
  onEmailChange,
  address1,
  onAddress1Change,
  address2,
  onAddress2Change,
  city,
  onCityChange,
  state,
  onStateChange,
  zipCode,
  onZipCodeChange,
}) => {

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant='h6'>Name</Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="first-name"
            label="First Name"
            variant="outlined"
            value={firstName}
            onChange={(e) => onFirstNameChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="last-name"
            label="Last Name"
            variant="outlined"
            value={lastName}
            onChange={(e) => onLastNameChange(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6'>Contact</Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="phone-number"
            label="Phone Number"
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => onPhoneNumberChange(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6'>Address</Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="addres-line-1"
            label="Address Line 1"
            variant="outlined"
            value={address1}
            onChange={(e) => onAddress1Change(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="address-line-2"
            label="Address Line 2"
            variant="outlined"
            value={address2}
            onChange={(e) => onAddress2Change(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="city"
            label="City"
            variant="outlined"
            value={city}
            onChange={(e) => onCityChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="state"
            label="State"
            variant="outlined"
            value={state}
            onChange={(e) => onStateChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="zip-code"
            label="Zip Code"
            variant="outlined"
            value={zipCode}
            onChange={(e) => onZipCodeChange(e.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
