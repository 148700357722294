import React from 'react';

import { Outlet } from "react-router-dom";

export const CardsWrapper: React.FC = () => {

  return (
    <Outlet />
  );
}
