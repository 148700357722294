import firebase from 'firebase/compat/app';
import { Timestamp } from "firebase/firestore";

import { auth, db } from "../../../../firebase";
import { DogBaseContext } from '../machines/contexts/base';
import { FileContext } from '../machines/contexts/file';
import { uploadFile } from './uploadFile';

type UploadDogMedicalHistoryDocumetnsContext = DogBaseContext & { medicalHistoryDocuments: FileContext[] };

export const uploadDogMedicalHistoryDocumetn = ({
  id,
  medicalHistoryDocuments,
}: UploadDogMedicalHistoryDocumetnsContext, { file }: { file: File }): Promise<FileContext[]> => new Promise((resolve, reject) => {
  const userId = auth.currentUser?.uid;

  uploadFile(`users/${userId}/dogs/${id}/medicalHistoryDocuments`, file)
    .then(ctx => {
      resolve([...medicalHistoryDocuments, ctx]);
    })
    .catch(reject);
});
