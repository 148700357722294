import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


import { EditDogLayout } from './components/layout';
import { ContactInformationForm, ContactInformationFormProps } from '../forms/contactInformation';
import { useParams } from 'react-router-dom';
import { EditDogActor } from '../machines/editDog';
import { store } from '../../../core/store';
import { useActor } from '@xstate/react';

export const EditDogContactInformation: React.FC = () => {
  const params = useParams();

  const service: EditDogActor = store.dogsService.get(params.dogId ?? '');
  const [state] = useActor(service);
  const send = service.send;

  const owner = state.context.owner;

  const contactInfoProps: ContactInformationFormProps = {
    onFirstNameChange: (firstName: string) => send('INPUT_OWNER_FIRST_NAME', { firstName }),
    onLastNameChange: (lastName: string) => send('INPUT_OWNER_LAST_NAME', { lastName }),
    onPhoneNumberChange: (phoneNumber: string) => send('INPUT_OWNER_PHONE_NUMBER', { phoneNumber }),
    onEmailChange: (email: string) => send('INPUT_OWNER_EMAIL', { email }),
    onAddress1Change: (address1: string) => send('INPUT_OWNER_ADDRESS_1', { address1 }),
    onAddress2Change: (address2: string) => send('INPUT_OWNER_ADDRESS_2', { address2 }),
    onCityChange: (city: string) => send('INPUT_OWNER_CITY', { city }),
    onStateChange: (state: string) => send('INPUT_OWNER_STATE', { state }),
    onZipCodeChange: (zipCode: string) => send('INPUT_OWNER_ZIP_CODE', { zipCode }),
    ...owner,
  };

  return (
    <EditDogLayout>
      <Grid
        container
        spacing={4}
      >
        <Grid item lg={6} md>
          <Grid item sx={{ mb: 4 }}>
            <Typography variant='h4'>Owner Contact Information</Typography>
          </Grid>
          <ContactInformationForm { ...contactInfoProps } />
        </Grid>
      </Grid>
    </EditDogLayout>
  );
}
