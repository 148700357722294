import React, { Fragment, useEffect } from 'react';

import { ActionObject, AnyEventObject, StateMachine } from 'xstate';
import { useActor, useMachine } from '@xstate/react';

import { Link, useNavigate, useParams } from "react-router-dom";

import { DateTime } from 'luxon';

import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { TopBar } from '../../../core/components/app-bar';

import { store } from '../../../core/store';
// import { UpsertDogCardContext, UpsertDogCardEvents } from './machine';
import { IconButton, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';
import { createListDogCardsMachine, PublicDogCardActorRef } from '../machines/listCards';

export const ListCards: React.FC = () => {
  const navigate = useNavigate();
  const { dogId } = useParams();

  const [ state, send ] = useMachine(() => createListDogCardsMachine({ dogId: dogId || '' }));
  const mobileSizing = useMediaQuery('(max-width:800px)');
  const spacing = mobileSizing ? 1 : 3;

  return (
    <Box
      sx={{
        p: spacing,
        pt: mobileSizing ? 2 : spacing,
        gap: spacing,
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box'
      }}
    >
      {
        state.context.publicCards.map(card => {
          return (
            <Card key={card.id} sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  { card.ref.state.context?.name ?? card.id }
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Created: { card.ref.state.context.createdAt?.toFormat('EEE, LLL dd, yyyy') ?? '' }
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => navigate(`/dogs/${dogId}/cards/public/${card.id}`)}
                >Edit</Button>
                <Button
                  size="small"
                  onClick={() => window.open(`${window.location.origin}/c/${card.id}`)}
                >View</Button>
              </CardActions>
            </Card>
          )
        })
      }
    </Box>
  );
}
