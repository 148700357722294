export  const createDogVeterinarianInformationContext = (): DogVeterinarianInformationContext => ({
  veterinarian: {
    name: '',
    website: '',
    phoneNumber: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  }
});

export interface DogVeterinarianInformationContext {
  veterinarian: {
    name: string;
    website: string;
    phoneNumber: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
  };
}
