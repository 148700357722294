import { storage } from "../../../../firebase";

export const cleanupDogProfilePictures = (ref?: string): Promise<void> =>
  new Promise((resolve, reject) => {
    if (!ref) {
      reject('No ref to test against.');
    } else {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(ref);

      const parent = imageRef.parent;

      if (!parent) {
        reject('ref malformed.');
      } else {
        parent.listAll()
          .then(res => {
            const filesToDelete = res.items
              .filter(f => f.fullPath !== ref);
            Promise.all(filesToDelete.map(f => f.delete()))
              .then(() => resolve())
              .catch(reject);
          })
          .catch(reject)
      }
    }
  });
