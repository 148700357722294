import React, { Fragment } from "react";

import { SnackbarKey } from "notistack";

import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export const createAction = (closeSnackbar: (snackbarKey?: SnackbarKey | undefined) => void) => (key: any) => (
  <Fragment>
    <IconButton
      aria-label="delete"
      onClick={() => { closeSnackbar(key) }}
      sx={{
        color: '#ffffff'
      }}
    >
      <Close color='inherit' />
    </IconButton>
  </Fragment>
);
