import AppBar from "@mui/material/AppBar";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 240;

interface BaseAppBarProps {
  color?: 'primary' | 'secondary';
}

export const BaseAppBar: React.FC<BaseAppBarProps> = ({ children, color }) => {
  const smallScreen = useMediaQuery('(max-width:800px)');

  const appBarColor = color ?? 'primary';

  return (
    <AppBar
      color={appBarColor}
      position="fixed"
      sx={
        smallScreen ? 
        {
          width: '100%',
        } :
        {
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`
        }
      }
    >
      { children }
    </AppBar>
  );
};