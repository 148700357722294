import firebase from 'firebase/compat/app';
import { Timestamp } from "firebase/firestore";

import { auth, db } from "../../../../firebase";
import { DogBaseContext } from '../machines/contexts/base';
import { FileContext } from '../machines/contexts/file';
import { uploadFile } from './uploadFile';

type DeleteDogOwnerDocumentsContext = DogBaseContext & { ownerDocuments: FileContext[] };

export const uploadDogOwnerDocument = ({
  id,
  ownerDocuments,
}: DeleteDogOwnerDocumentsContext, { file }: { file: File }): Promise<FileContext[]> => new Promise((resolve, reject) => {
  const userId = auth.currentUser?.uid;

  uploadFile(`users/${userId}/dogs/${id}/ownerDocuments`, file)
    .then(ctx => {
      resolve([...ownerDocuments, ctx]);
    })
    .catch(reject);
});
