import { DateTime } from "luxon";
import { assign, ActionObject, createMachine, TransitionsConfig, interpret, Interpreter } from "xstate";
import { auth } from "../../../../../firebase";
import { timestampToDateTime } from "../../../../../utils/transform-date";
import { CardAccess, store } from "../../../../core/store";
import { createDogCardPublicService, createShareUrl } from "../services/createDogCardService";
import { deleteDogCardPublicService } from "../services/deleteDogCardPublicService";
import { DogCardData } from "../services/publicCardDTO";
import { updateDogCardPublicService } from "../services/updateDogCardPublicService";
import { DogCardBaseContext, DogCardCheckContext, DogCardEntitiesContext, DogCardShareableContext } from "./contexts/base";
import { createDogCardPersonalContext, DogCardPersonalContext, DogCardPersonalKey } from "./contexts/configuration";
import { CreateDogCardContext } from "./createCard";

interface EditPublicDogCardMachineProps {
  dogId: string;
}

export interface DogCardPublic {
  personal: DogCardPersonalContext;
  name: string;
}

export type EditPublicDogCardContext =
  & DogCardBaseContext
  & DogCardEntitiesContext
  & DogCardShareableContext
  & DogCardPublic;

type INPUT_PERSONAL = { type: 'INPUT_PERSONAL', key: DogCardPersonalKey, checked: boolean };
type INPUT_NAME = { type: 'INPUT_NAME', name: string };
type EditPublicDogCardEvent =
  | INPUT_PERSONAL
  | INPUT_NAME
  | { type: 'UPDATE' }
  | { type: 'DISCARD_CHANGES' }
  | { type: 'DELETE' }
  | { type: 'CONFIRM_DELETE' }
  | { type: 'CANCEL_DELETE' };

export type EditPublicDogCardActor = Interpreter<EditPublicDogCardContext, any, EditPublicDogCardEvent, {
  value: any;
  context: EditPublicDogCardContext;
}>;

export const createEditPublicDogCardContextFromCreateCard = (context: CreateDogCardContext): EditPublicDogCardContext => ({
  ...context,
});
export const createEditPublicDogCardContextFromPublicCardData = (dogCard: DogCardData): EditPublicDogCardContext =>
  Object.assign(
    { personal: createDogCardPersonalContext() },
    { ...dogCard },
    { url: createShareUrl({ id: dogCard.id }) },
    { createdAt: timestampToDateTime(dogCard.createdAt) },
    { updatedAt: timestampToDateTime(dogCard.updatedAt) }
  );

const commonActions = {
  INPUT_PERSONAL: {
    target: 'editing',
    actions: ['setPersonal']
  },
  INPUT_NAME: {
    target: 'editing',
    actions: ['setName']
  }
};

export const createEditPublicDogCardMachine = (context: EditPublicDogCardContext) => {
  const machineId = `edit-public-dog-card-${context.id}`;
  return createMachine<EditPublicDogCardContext, EditPublicDogCardEvent>({
    id: machineId,

    initial: 'view',

    context,

    states: {
      view: {
        on: {
          ...commonActions,
          DELETE: 'confirmDelete',
        }
      },
      editing: {
        on: {
          ...commonActions,
          DISCARD_CHANGES: {
            target: 'view',
            actions: ['discardChanges']
          },
          UPDATE: 'updating',
        }
      },
      updating: {
        invoke: {
          src: (context, event) => updateDogCardPublicService(context, event),
          onDone: '.success',
          onError: '.failed',
        },
        initial: 'inProgress',
        states: {
          inProgress: {},
          success: {
            after: {
              1: {
                target: `#${machineId}.view`,
                actions: ['resetContext']
              }
            }
          },
          failed: { after: { 1: { target: `#${machineId}.editing` } } },
        }
      },
      confirmDelete: {
        on: {
          CONFIRM_DELETE: 'deleting',
          CANCEL_DELETE: 'view',
        }
      },
      deleting: {
        invoke: {
          src: (context, event) => deleteDogCardPublicService(context, event),
          onDone: '.success',
          onError: '.failed',
        },
        initial: 'inProgress',
        states: {
          inProgress: {},
          success: {},
          failed: { after: { 1: { target: `#${machineId}.view` } } },
        }
      },
    },
  },
  {
    actions: {
      setPersonal: assign({
        personal: (context, event) => {
          const e = event as INPUT_PERSONAL;
          return { ...context.personal, [e.key]: e.checked };
        }
      }),
      setName: assign({
        name: (context, event) => (event as INPUT_NAME).name
      }),
      discardChanges: assign((context, event) =>
        Object.assign(
          { ...store.cardsContext.get(context.id, CardAccess.Public) },
        )),
      resetContext: (context, event) => {
        store.cardsContext.set(context.id, CardAccess.Public, context)
      }
    },
  });
}

export const spawnEditPublicDogCard = (context: EditPublicDogCardContext) =>
  interpret(createEditPublicDogCardMachine(context)).start();
