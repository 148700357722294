import firebase from 'firebase/compat/app';
import { Timestamp } from "firebase/firestore";

import { auth, db } from "../../../../firebase";
import { DogPersonalInformationContext } from "../machines/contexts/personal";

export const createDogService = ({
  name,
  breed,
  dateOfBirth,
  sex,
  weight,
  microchipped,
  definingMarkings,
  careInformation,
}: DogPersonalInformationContext, event: any): Promise<string> => new Promise((resolve, reject) => {
  const userId = auth.currentUser?.uid;
  db.collection(`users/${userId}/dogs`)
    .add({
      name,
      breed,
      dateOfBirth: dateOfBirth && Timestamp.fromDate(dateOfBirth.toJSDate()),
      sex,
      weight,
      microchipped,
      definingMarkings,
      careInformation,
      createdAt: firebase.firestore.Timestamp.now(),
    })
    .then((docRef) => {
      resolve(docRef.id);
    })
    .catch((error) => {
      reject(error);
    });
});
