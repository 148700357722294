import { getBlob } from "firebase/storage";
import { auth, storage } from "../../../../firebase";

export type UploadFileOptions = {
  cacheControl: string;
}
// private, max-age=300

export const uploadFile = (path: string, file: File, options?: UploadFileOptions): Promise<{ ref: string, name: string, url: string }> =>
  new Promise((resolve, reject) => {
    const { cacheControl } = options ?? {};

    const userId = auth.currentUser?.uid || '';

    if (!userId) {
      reject('User not set.');
    }

    const filePath = `${path}/${file.name}`;
    const storageRef = storage.ref();
    const imageRef = storageRef.child(filePath);
    imageRef.put(file, { cacheControl })
      .then((snapshot) => {
        snapshot.ref.getDownloadURL()
          .then(url => {
            resolve({
              ref: snapshot.ref.fullPath,
              name: snapshot.ref.name,
              url,
            })
          })
          .catch(error => {
            reject(error);
          })
      })
      .catch((error) => {
        reject(error);
      });
  });
