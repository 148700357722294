import { ActionObject, createMachine, interpret, TransitionsConfig, assign, Interpreter } from "xstate";
import { DateTime } from "luxon";

import { store } from "../../../core/store";
import { getPublicCardService } from "../services/getPublicCard";

export type DisplayPublicCardContext = {
  cardId: string,
  name?: string,
  breed?: string,
  dateOfBirth?: DateTime,
  sex?: string,
  weight?: {
    value: number,
    unit: string,
  },
  microchipped?: boolean,
  definingMarkings?: string,
  careInformation?: string,
};

export type DisplayPublicCardEvent =
  | { type: 'NOOP' };

export type EditDogActor = Interpreter<DisplayPublicCardContext, any, DisplayPublicCardEvent, {
  value: any;
  context: DisplayPublicCardContext;
}>;

export const createDisaplyPublicCardMachine = (cardId: string) => {
  const machineId = `display-card-${cardId}`;
  return createMachine<DisplayPublicCardContext, DisplayPublicCardEvent>({
    id: machineId,

    type: 'parallel',

    context: {
      cardId,
    },

    states: {
      data: {
        initial: 'loadCard',
        states: {
          loadCard: {
            invoke: {
              src: (context, event) => getPublicCardService(context, event),
              onDone: {
                target: 'view',
                actions: ['setPublicCard'],
              },
              onError: 'failed',
            },
          },
          failed: {},
          view: {},
        }
      },
    },
  },
  {
    actions: {
      setPublicCard: assign((context, event: any) => ({
        ...context,
        ...event.data,
      }))
    },
  });
}
