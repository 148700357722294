import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { EditDogActor } from '../../machines/editDog';
import { useNavigate, useParams } from 'react-router-dom';
import { store } from '../../../../core/store';
import { useActor } from '@xstate/react';
import { AppBarAction, appBarService, AppBarState } from '../../../../../utils/app-bar-hooks';
import { createAction } from '../../../../core/components/close-snack';
import { useSnackbar } from 'notistack';
import { useSubscription } from 'observable-hooks';

export const EditDogLayout: React.FC = ({
  children,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const service: EditDogActor = store.dogsService.get(params.dogId ?? '');
  const [state] = useActor(service);
  const send = service.send;

  const name = state.context.name;

  useEffect(() => {
    // if (['data.editing', 'data.updating'].some(state.matches)) {
    //   appBarService.setState(AppBarState.Edit);
    //   appBarService.setTitle('Unsaved Changes');
    // } else {
    //   appBarService.setState(AppBarState.View);
    //   appBarService.setTitle(name);
    // }
    if (state.matches('data.updating.success')) {
      enqueueSnackbar(`Successfully updated ${name}.`, {
        variant: 'success',
        action: createAction(closeSnackbar),
      });
    } else if (state.matches('data.updating.failed')) {
      enqueueSnackbar(`Failed to update ${name}. Please try again.`, {
        variant: 'error',
        action: createAction(closeSnackbar),
      });
    } else if (state.matches('data.deleting.success')) {
      enqueueSnackbar(`Successfully deleted ${name}.`, {
        variant: 'success',
        action: createAction(closeSnackbar),
      });
      navigate(-1);
    } else if (state.matches('data.deleting.failed')) {
      enqueueSnackbar(`Failed to delete ${name}. Please try again.`, {
        variant: 'error',
        action: createAction(closeSnackbar),
      });
    }
  }, [state.value]);

  // useSubscription<AppBarAction>(appBarService.getAction(), appBarAction => {
  //   if (appBarAction === AppBarAction.Discard) {
  //     send('DISCARD_CHANGES');
  //   } else if (appBarAction === AppBarAction.Save) {
  //     send('UPDATE');
  //   } else if (appBarAction === AppBarAction.Delete) {
  //     send('DELETE');
  //   }
  // });

  return (
    <Box
      sx={{
        m: 2,
        mt: 6,
        mb: 6,
      }}
    >
      { children }
      <Dialog
        open={ state.matches('data.confirmDelete') }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete { name }?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will remove { name } from your Dogs list. 
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description-2"
            sx={{
              fontWeight: 600,
            }}
          >
            This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => send('CANCEL_DELETE') }>Cancel</Button>
          <Button onClick={ () => send('CONFIRM_DELETE') }>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
