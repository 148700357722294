import React from 'react';

import { getAuth, signOut } from "firebase/auth";

import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTitle } from '../../utils/app-bar-hooks';

export const Settings: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  useTitle('Settings');

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
    .then(() => {
      // Sign-out successful.
    }).catch((error) => {
      enqueueSnackbar('Logout failed. Please try again.', {
        variant: 'error'
      });
    });
  }

  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      <Button
        onClick={ logout }
        variant="text"
      >Logout</Button>
    </Box>
  )
}
