import React, { useEffect } from 'react';

import { useActor, useMachine } from '@xstate/react';

import { useNavigate } from "react-router-dom";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { store } from '../../core/store';

import { TopBar } from '../../core/components/app-bar';

import { DogActorRef, listDogsMachine } from './machine';
import { createDogCardConfigMachine } from '../cards/upsert/machine';
import { ListDogCard } from './components/card';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';
import { ListDogCardSkeleton } from './components/card-skeleton';
import { useTitle } from '../../../utils/app-bar-hooks';

export const ListDogs: React.FC = () => {
  const mobileSizing = useMediaQuery('(max-width:800px)');
  const navigate = useNavigate();
  useTitle('Dogs');
  const [state, send] = useMachine(listDogsMachine);
  console.log(state.context.dogs)

  const setupDogCard = (dog: string) => {
    navigate(`/dogs/${dog}/cards/new`);
  }

  const spacing = mobileSizing ? 1 : 3;

  return (
    <Box
      sx={{
        p: spacing,
        pt: mobileSizing ? 2 : spacing,
        gap: spacing,
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box'
      }}
    >
      {
        state.matches('getDogs') ?
          [1, 2, 3].map(skeleton =>
            (<ListDogCardSkeleton key={skeleton} />)) :
          state.context.dogs.map(({ id, ref }: DogActorRef) =>
              (<ListDogCard
                  key={id}
                  dog={ ref }
                  onClick={() => { navigate(`/dogs/${id}/personal`) }}
                  onShare={() => { setupDogCard(id) }}
              />))
      }

      {
        state.matches('failed') ?
          <Box>
            <Typography>
              Could not load dogs.
              <Button
                onClick={() => send('RETRY')}
              >Retry</Button>
            </Typography>
            
          </Box> :
          null
      }

      <Fab
        onClick={() => navigate('/dogs/new')}
        sx={{
          position: 'fixed',
          right: 20,
          bottom: 20,
        }}
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}
