import React, { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { DogSex, Weight } from '../enums';

export interface DogInformationFormProps {
  name: string;
  onNameChange: (name: string) => void;
  breed: string;
  onBreedChange: (breed: string) => void;
  dateOfBirth: DateTime | null;
  onDateOfBirthChange: (dateOfBirth: DateTime | null) => void;
  sex: DogSex;
  onSexChange: (sex: DogSex) => void;
  weight: {
    value: number;
    unit: Weight;
  };
  onWeightValueChange: (weightValue: number) => void;
  onWeightUnitChange: (weightUnit: Weight) => void;
  microchipped: boolean;
  onMicrochippedChange: (microchipped: boolean) => void;
  definingMarkings: string;
  onDefiningMarkingsChange: (definingMarkings: string) => void;
  careInformation: string;
  onCareInformationChange: (careInformation: string) => void;
}

export const DogInformationForm: React.FC<DogInformationFormProps> = ({
  name,
  onNameChange,
  breed,
  onBreedChange,
  dateOfBirth,
  onDateOfBirthChange,
  sex,
  onSexChange,
  weight,
  onWeightValueChange,
  onWeightUnitChange,
  microchipped,
  onMicrochippedChange,
  definingMarkings,
  onDefiningMarkingsChange,
  careInformation,
  onCareInformationChange,
}) => {
  const [weightValue, setWeight] = useState(weight.value.toFixed(2));

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          id="name"
          label="Name"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          id="breed"
          label="Breed"
          value={breed}
          onChange={(e) => onBreedChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            disableFuture
            label="Date of Birth"
            value={dateOfBirth}
            onChange={(value) => onDateOfBirthChange(value)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="label-sex">Sex</InputLabel>
          <Select
            labelId="label-sex"
            id="sex"
            label="Sex"
            value={sex}
            onChange={(e) => onSexChange(e.target.value as DogSex)}
          >
            <MenuItem value={ DogSex.Male }>{ DogSex.Male }</MenuItem>
            <MenuItem value={ DogSex.Female }>{ DogSex.Female }</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid container spacing={2} item xs={12}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="weight-value"
            label="Weight"
            variant="outlined"
            // type="number"
            value={ weightValue }
            onBlur={(e) => onWeightValueChange(parseFloat(weightValue))}
            onChange={(e) => setWeight(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="label-weight-unit">Unit</InputLabel>
            <Select
              labelId="label-weight-unit"
              id="weight-unit"
              label="Unit"
              value={ weight.unit }
              onChange={(e) => onWeightUnitChange(e.target.value as Weight)}
            >
              <MenuItem value={ Weight.Pounds }>{ Weight.Pounds }</MenuItem>
              <MenuItem value={ Weight.Kilos }>{ Weight.Kilos }</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              id="microchipped"
              checked={ microchipped }
              onChange={(e) => onMicrochippedChange(e.target.checked)}
            />
          }
          label="Microchipped"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          id="defining-markings"
          label="Defining Markings"
          multiline
          minRows={10}
          value={ definingMarkings }
          onChange={(e) => onDefiningMarkingsChange(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          id="care-information"
          label="Care Information"
          multiline
          minRows={10}
          value={ careInformation }
          onChange={(e) => onCareInformationChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
