import React, { useState } from 'react';

import { useNavigate, Navigate, useParams } from "react-router-dom";
import { useMachine } from '@xstate/react';

import { createCreateDogCardMachine } from '../machines/createCard';
import { DogCardPersonalInformationConfigurationForm, DogCardPersonalInformationConfigurationFormProps, SetPersonalProps } from '../forms/dogPersonalInformation';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export interface PublicDogCardConfigurationFormProps extends DogCardPersonalInformationConfigurationFormProps {
  name: string;
  onSetName: (name: string) => void;
}

export const PublicDogCardConfigurationForm: React.FC<PublicDogCardConfigurationFormProps> = ({
  onSetAll,
  onSetName,
  onSetPersonal,
  name,
  personal
}) => {
  const dogCardPersonalInfoProps: DogCardPersonalInformationConfigurationFormProps = {
    personal,
    onSetAll,
    onSetPersonal,
  };

  return (
    <Box>
      <TextField
        sx={{ mb: 1 }}
        value={ name }
        onChange={ e => onSetName(e.target.value) }
        id="card-name"
        label="Name"
        variant="outlined"
      />

      <DogCardPersonalInformationConfigurationForm {...dogCardPersonalInfoProps} />
    </Box>
  );
}
