import React, { useEffect } from 'react';

import { useActor, useMachine } from '@xstate/react';

import { useNavigate } from "react-router-dom";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import ImageIcon from '@mui/icons-material/Image';
import Paper from '@mui/material/Paper';
import CardActionArea from '@mui/material/CardActionArea';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { EditDogActor } from '../../dog/machines/editDog';

// import { store } from '../../core/store';

// import { TopBar } from '../../core/components/app-bar';

// import { listDogsMachine } from './machine';
// import { createDogCardConfigMachine } from '../cards/upsert/machine';

export interface ListDogCardProps {
  dog: EditDogActor;
  onClick: () => void;
  onShare: () => void;
}

export const ListDogCard: React.FC<ListDogCardProps> = ({ dog, onClick, onShare }) => {
  const autoWidthCard = useMediaQuery('(max-width:800px)');

  const [state, send] = useActor(dog);
  
  const { id, name, breed, profilePicture } = state.context;

  const avatarUrl = profilePicture.url;

  const avatarSize = autoWidthCard ? 100 : 200;
  
  const avatar = avatarUrl ?
    (
      <CardMedia
        component="img"
        height={avatarSize}
        width={avatarSize}
        image={ avatarUrl }
        alt={ name }
      />
    ) :
    (
      <Paper
        sx={{
          height: avatarSize,
          width: avatarSize,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        elevation={0}
      >
        <ImageIcon
          color='secondary'
          sx={{
            fontSize: autoWidthCard ? '3rem' : '8rem',
          }}
        />
      </Paper>
    )

  return (
    <Card
      variant="outlined"
      sx={{
        width: autoWidthCard ? '100%' : 500,
        display: 'flex',
      }}
    >
      <CardActionArea
        sx={{
          display: 'flex',
        }}
        onClick={onClick}
      >  
        { avatar }
        <CardContent
          sx={{
            flex: 1,
          }}
        >
          <Typography variant="h6" component="div">
            { name }
          </Typography>
          <Typography variant="subtitle1" component="div">
            { breed }
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: "center",
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="primary"
          aria-label="share"
          sx={{ m: 2 }}
          onClick={onShare}
        >
          <ShareIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
