import firebase from 'firebase/compat/app';
import { Timestamp } from "firebase/firestore";
import { DateTime } from 'luxon';

import { appCheck, auth, db } from "../../../../firebase";
import { timestampToDateTime } from '../../../../utils/transform-date';
import { DisplayPublicCardContext } from '../machines/displayCard';
import { PublicCardData } from './publicCardDTO';

const PUBLIC_CARD_URL = 'https://us-central1-dogcard-309bd.cloudfunctions.net/api/publicCard/:cardId';

interface PublicCardResponse {
  name?: string;
  breed?: string;
  dateOfBirth: { _seconds: number } | null;
  sex?: string;
  weight?: {
    value: number;
    unit: string;
  },
  microchipped?: boolean;
  definingMarkings?: string;
  careInformation?: string;
}

const mapToPublicCardData = (response: PublicCardResponse): PublicCardData => {
  return ({
    ...response,
    dateOfBirth: (response.dateOfBirth && DateTime.fromJSDate(new Date(response.dateOfBirth._seconds * 1000))) || undefined
  })
}

export const getPublicCardService = ({
  cardId,
}: DisplayPublicCardContext, event: any): Promise<PublicCardData> => new Promise((resolve, reject) => {
  appCheck.getToken()
    .then(({ token }) => {
      const headers = new Headers();
      headers.append("X-Firebase-AppCheck", token);
      const requestOptions = {
        method: 'GET',
        headers,
      };
      fetch(PUBLIC_CARD_URL.replace(':cardId', cardId), requestOptions)
        .then(response => response.json())
        .then(mapToPublicCardData)
        .then(resolve)
        .catch(e => {
          console.log(e)
          reject(e)
        });
    })
    .catch(reject);

});
