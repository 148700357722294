import React from 'react';
import { Route } from 'react-router-dom';

import { DogsWrapper } from '../index';
import { UpsertCard } from '../cards/upsert'
import { ListDogs } from '../list';
import { DogWrapper } from '../dog';
import { CardsWrapper } from '../cards';
import { CardWrapper } from '../cards/card';
import { ListCards } from '../cards/list';

import { CreateDog } from '../dog/create';
import { EditDogPersonalInformation } from '../dog/edit/personal';
import { EditDogContactInformation } from '../dog/edit/contact';
import { EditDogVeterinarianInformation } from '../dog/edit/veterinarian';
import { EditDogMedicalHistory } from '../dog/edit/medicalHistory';
import { EditDogOwnerDocuments } from '../dog/edit/ownerDocuments';

import { CreateDogCard } from '../cards/card/create';
import { EditPublicDogCard } from '../cards/card/edit/public';

export const dogRoutes = () => {
  return (
    <Route path="/dogs" element={ <DogsWrapper /> }>
      <Route index element={ <ListDogs /> }/>
      <Route path="new" element={ <CreateDog /> } />
      <Route path=":dogId" element={ <DogWrapper /> }>
        <Route path="personal" element={ <EditDogPersonalInformation /> }/>
        <Route path="contact" element={ <EditDogContactInformation /> }/>
        <Route path="veterinarian" element={ <EditDogVeterinarianInformation /> }/>
        <Route path="medical" element={ <EditDogMedicalHistory /> }/>
        <Route path="owner" element={ <EditDogOwnerDocuments /> }/>
        <Route path="cards" element={ <CardsWrapper /> }>
          <Route path="public">
            <Route index element={ <ListCards /> } />
            <Route path=":cardId" element={ <EditPublicDogCard /> }/>
          </Route>
          <Route path="private" element={ <ListCards /> }/>
          <Route path="new" element={ <CreateDogCard /> }/>
        </Route>
      </Route>
    </Route>
  )
}
