import { assign } from "xstate";

import { DogOwnerContactInformationContext } from "../contexts/owner";

export type INPUT_OWNER_FIRST_NAME = { type: 'INPUT_OWNER_FIRST_NAME', firstName: string };
export type INPUT_OWNER_LAST_NAME = { type: 'INPUT_OWNER_LAST_NAME', lastName: string };
export type INPUT_OWNER_PHONE_NUMBER = { type: 'INPUT_OWNER_PHONE_NUMBER', phoneNumber: string };
export type INPUT_OWNER_EMAIL = { type: 'INPUT_OWNER_EMAIL', email: string };
export type INPUT_OWNER_ADDRESS_1 = { type: 'INPUT_OWNER_ADDRESS_1', address1: string };
export type INPUT_OWNER_ADDRESS_2 = { type: 'INPUT_OWNER_ADDRESS_2', address2: string };
export type INPUT_OWNER_CITY = { type: 'INPUT_OWNER_CITY', city: string };
export type INPUT_OWNER_STATE = { type: 'INPUT_OWNER_STATE', state: string };
export type INPUT_OWNER_ZIP_CODE = { type: 'INPUT_OWNER_ZIP_CODE', zipCode: string };

export type DogOwnerContactInformationEvent =
  | INPUT_OWNER_FIRST_NAME
  | INPUT_OWNER_LAST_NAME
  | INPUT_OWNER_PHONE_NUMBER
  | INPUT_OWNER_EMAIL
  | INPUT_OWNER_ADDRESS_1
  | INPUT_OWNER_ADDRESS_2
  | INPUT_OWNER_CITY
  | INPUT_OWNER_STATE
  | INPUT_OWNER_ZIP_CODE;

export const setOwnerFirstName = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, firstName: (event as INPUT_OWNER_FIRST_NAME).firstName })
});
export const setOwnerLastName = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, lastName: (event as INPUT_OWNER_LAST_NAME).lastName })
});
export const setOwnerPhoneNumber = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, phoneNumber: (event as INPUT_OWNER_PHONE_NUMBER).phoneNumber })
});
export const setOwnerEmail = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, email: (event as INPUT_OWNER_EMAIL).email })
});
export const setOwnerAddress1 = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, address1: (event as INPUT_OWNER_ADDRESS_1).address1 })
});
export const setOwnerAddress2 = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, address2: (event as INPUT_OWNER_ADDRESS_2).address2 })
});
export const setOwnerCity = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, city: (event as INPUT_OWNER_CITY).city })
});
export const setOwnerState = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, state: (event as INPUT_OWNER_STATE).state })
});
export const setOwnerZipCode = assign<DogOwnerContactInformationContext, DogOwnerContactInformationEvent>({
  owner: (context, event) => ({ ...context.owner, zipCode: (event as INPUT_OWNER_ZIP_CODE).zipCode })
});

export const actions = {
  setOwnerFirstName,
  setOwnerLastName,
  setOwnerPhoneNumber,
  setOwnerEmail,
  setOwnerAddress1,
  setOwnerAddress2,
  setOwnerCity,
  setOwnerState,
  setOwnerZipCode,
};
