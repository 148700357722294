import { assign } from "xstate";

import { DogVeterinarianInformationContext } from "../contexts/veterinarian";

export type INPUT_VETERINARIAN_NAME = { type: 'INPUT_VETERINARIAN_NAME', name: string };
export type INPUT_VETERINARIAN_WEBSITE = { type: 'INPUT_VETERINARIAN_WEBSITE', website: string };
export type INPUT_VETERINARIAN_PHONE_NUMBER = { type: 'INPUT_VETERINARIAN_PHONE_NUMBER', phoneNumber: string };
export type INPUT_VETERINARIAN_EMAIL = { type: 'INPUT_VETERINARIAN_EMAIL', email: string };
export type INPUT_VETERINARIAN_ADDRESS_1 = { type: 'INPUT_VETERINARIAN_ADDRESS_1', address1: string };
export type INPUT_VETERINARIAN_ADDRESS_2 = { type: 'INPUT_VETERINARIAN_ADDRESS_2', address2: string };
export type INPUT_VETERINARIAN_CITY = { type: 'INPUT_VETERINARIAN_CITY', city: string };
export type INPUT_VETERINARIAN_STATE = { type: 'INPUT_VETERINARIAN_STATE', state: string };
export type INPUT_VETERINARIAN_ZIP_CODE = { type: 'INPUT_VETERINARIAN_ZIP_CODE', zipCode: string };

export type DogVeterinarianInformationEvent =
  | INPUT_VETERINARIAN_NAME
  | INPUT_VETERINARIAN_WEBSITE
  | INPUT_VETERINARIAN_PHONE_NUMBER
  | INPUT_VETERINARIAN_EMAIL
  | INPUT_VETERINARIAN_ADDRESS_1
  | INPUT_VETERINARIAN_ADDRESS_2
  | INPUT_VETERINARIAN_CITY
  | INPUT_VETERINARIAN_STATE
  | INPUT_VETERINARIAN_ZIP_CODE;

export const setVeterinarianName = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, name: (event as INPUT_VETERINARIAN_NAME).name })
});
export const setVeterinarianWebsite = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, website: (event as INPUT_VETERINARIAN_WEBSITE).website })
});
export const setVeterinarianPhoneNumber = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, phoneNumber: (event as INPUT_VETERINARIAN_PHONE_NUMBER).phoneNumber })
});
export const setVeterinarianEmail = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, email: (event as INPUT_VETERINARIAN_EMAIL).email })
});
export const setVeterinarianAddress1 = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, address1: (event as INPUT_VETERINARIAN_ADDRESS_1).address1 })
});
export const setVeterinarianAddress2 = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, address2: (event as INPUT_VETERINARIAN_ADDRESS_2).address2 })
});
export const setVeterinarianCity = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, city: (event as INPUT_VETERINARIAN_CITY).city })
});
export const setVeterinarianState = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, state: (event as INPUT_VETERINARIAN_STATE).state })
});
export const setVeterinarianZipCode = assign<DogVeterinarianInformationContext, DogVeterinarianInformationEvent>({
  veterinarian: (context, event) => ({ ...context.veterinarian, zipCode: (event as INPUT_VETERINARIAN_ZIP_CODE).zipCode })
});

export const actions = {
  setVeterinarianName,
  setVeterinarianWebsite,
  setVeterinarianPhoneNumber,
  setVeterinarianEmail,
  setVeterinarianAddress1,
  setVeterinarianAddress2,
  setVeterinarianCity,
  setVeterinarianState,
  setVeterinarianZipCode,
};
