import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { Signin } from '../signin';
import { Signup } from '../signup';
import { ForgotPassword } from '../forgot-password';

// const redirect = (<Navigate to="/dogs" />);

// export const createAuthenticationRoutes = (authenticated: boolean) => [
//   {
//     path: '/login',
//     element: authenticated ? redirect : <Signin />,
//   },
//   {
//     path: '/signup',
//     element: authenticated ? redirect : <Signup />,
//   },
//   {
//     path: '/forgot-password',
//     element: authenticated ? redirect : <ForgotPassword />,
//   },
// ];

export const authenticationRoutes = () => {
  return (
    <Route>
      <Route path="/login" element={ <Signin /> } />
      <Route path="/signup" element={ <Signup /> } />
      <Route path="/forgot-password" element={ <ForgotPassword /> } />
    </Route>
  )
}

