import React, { useState } from 'react';

import { Link, useNavigate } from "react-router-dom";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import './manual-auth-form.css';

export enum AuthTypes {
  Signin = "Signin",
  Signup = 'Signup',
  ForgotPassword = 'ForgotPassword',
}

export const ManualAuthForm = ({ type }: any) => {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signupWithEmailAndPassword = () => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // // Signed in 
        // const user = userCredential.user;
        // // ...
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
  }
  const signinWithEmailAndPassword = () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // // Signed in 
        // const user = userCredential.user;
        // // ...
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
  }
  const sendPasswordReset = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // ..
      });
  }

  let actionText = null;
  let navText = null;
  let action = () => {};
  switch(type) {
    case AuthTypes.Signin:
      actionText = 'Login';
      navText = (<div>Don't have an account? <Link to="/signup">Create One</Link></div>);
      action = signinWithEmailAndPassword;
      break;
    case AuthTypes.Signup:
      actionText = 'Sign Up';
      navText = (<div>Already have an account? <Link to="/login">Login</Link></div>);
      action = signupWithEmailAndPassword;
      break;
    case AuthTypes.ForgotPassword:
      actionText = 'Reset';
      navText = (<div>Don't have an account? <Link to="/signup">Create One</Link></div>);
      action = sendPasswordReset;
      break;
  }

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 300,
        margin: '0 auto',
        height: '100vh',
      }}
      noValidate
      autoComplete="off"
    >
      <h1 className='auth__title'>Dog Card</h1>

      <TextField
        margin="normal"
        id="outlined-basic"
        label="Email"
        variant="outlined"
        onChange={(e) => setEmail(e.target.value)}
      />
      
      {
        (type === AuthTypes.Signin || type === AuthTypes.Signup) ?
          (<TextField
            margin="normal"
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
          />) : null
      }

      {
        (type === AuthTypes.Signin) ? (<Link to="/forgot-password">Forgot Password?</Link>) : null
      }

      <Button
        onClick={ action }
        variant="outlined"
        sx={{
          marginTop: '1rem'
        }}
      >{ actionText }</Button>

      { navText }
    </Box>
  );
}
