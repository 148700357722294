import firebase from 'firebase/compat/app';
import { db } from "../../../../../firebase";
import { DogCardBaseContext, DogCardCheckContext, DogCardEntitiesContext } from "../machines/contexts/base";
import { DogCardPersonalContext } from '../machines/contexts/configuration';

type DeleteDogCardProps =
  & DogCardBaseContext
  & DogCardEntitiesContext;

export const deleteDogCardService = (context: DeleteDogCardProps, event: any): Promise<void> =>
  new Promise((resolve, reject) => {
    const {
      dogId,
      userId,
      id,
    } = context;

    console.log(id)

    if (!id)  throw new Error('No id');
    else if (!userId) throw new Error('No user id');
    else if (!dogId) throw new Error('No dog id');

    const batch = db.batch();

    const cardRef = db.collection('publicCards').doc(id);
    const userCardRef = db.collection(`users/${userId}/dogs/${dogId}/publicCards`).doc(cardRef.id);
    
    batch.delete(cardRef);
    batch.delete(userCardRef);

    batch.commit()
      .then(() => {
        resolve();
      })
      .catch((e) => {
        console.log(e)
        reject(e)
      });
  });

type CreateDogCardPublicProps = DeleteDogCardProps;
export const deleteDogCardPublicService = (context: CreateDogCardPublicProps, event: any): Promise<void> =>
  deleteDogCardService(context, event);
