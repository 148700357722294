import firebase from 'firebase/compat/app';
import { db } from "../../../../../firebase";
import { DogCardCheckContext, DogCardEntitiesContext } from "../machines/contexts/base";
import { DogCardPersonalContext } from '../machines/contexts/configuration';

type CreateShareUrlProps = { id: string };
export const createShareUrl = ({ id }: CreateShareUrlProps) => {
  const domain = window.location.host;
  const cardsPath = 'c';
  return `${domain}/${cardsPath}/${id}`;
}

type DogCardPersonal = {
  personal: DogCardPersonalContext,
  name: string,
};

type CreateDogCardProps =
  & DogCardEntitiesContext
  & DogCardPersonal;

type DogCardResult = {
  url: string;
  id: string;
};

export const createDogCardService = (context: CreateDogCardProps, event: any): Promise<DogCardResult> =>
  new Promise((resolve, reject) => {
    const {
      dogId,
      userId,
    } = context;

    if (!userId) throw new Error('No user id');
    else if (!dogId) throw new Error('No dog id');

    const batch = db.batch();

    const cardRef = db.collection('publicCards').doc();
    const cardProps = {
      ...context,
      createdAt: firebase.firestore.Timestamp.now(),
    };
    batch.set(cardRef, cardProps);

    const userCardRef = db.collection(`users/${userId}/dogs/${dogId}/publicCards`).doc(cardRef.id);
    batch.set(userCardRef, cardProps);

    batch.commit()
      .then(() => {
        const url = createShareUrl({
          id: cardRef.id,
        });
        console.log(url);
        resolve({
          url,
          id: cardRef.id,
        });
      })
      .catch((e) => {
        console.log(e)
        reject(e)
      });
  });

type CreateDogCardPublicProps = CreateDogCardProps & DogCardPersonal;
export const createDogCardPublicService = (context: CreateDogCardPublicProps, event: any): Promise<DogCardResult> =>
  createDogCardService({
    name: context.name,
    dogId: context.dogId,
    userId: context.userId,
    personal: context.personal,
  }, event);
