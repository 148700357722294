import React, { useEffect, useState } from "react";

import { Subject } from "rxjs";

const titleSubject = new Subject<string>();

export enum AppBarState {
  View,
  Edit
}
const stateSubject = new Subject<AppBarState>();

export enum AppBarAction {
  Discard,
  Save,
  Delete
}
const actionSubject = new Subject<AppBarAction>();

export const appBarService = {
  setTitle: (title: string) => titleSubject.next(title),
  getTitle: () => titleSubject.asObservable(),
  setState: (state: AppBarState) => stateSubject.next(state),
  getState: () => stateSubject.asObservable(),
  discard: () => actionSubject.next(AppBarAction.Discard),
  save: () => actionSubject.next(AppBarAction.Save),
  delete: () => actionSubject.next(AppBarAction.Delete),
  getAction: () => actionSubject.asObservable(),
};

export const useTitle = (title: string, state?: AppBarState) => {
  useEffect(() => {
    appBarService.setTitle(title);
    appBarService.setState(state || AppBarState.View);
  }, [])
}
