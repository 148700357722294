// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/app-check';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5a_EjwBeO3frBVSWDqsE3XNW2aHW2HOU",
  authDomain: "dogcard-309bd.firebaseapp.com",
  projectId: "dogcard-309bd",
  storageBucket: "dogcard-309bd.appspot.com",
  messagingSenderId: "411749988847",
  appId: "1:411749988847:web:fef7528a5b4d96e00a5e1a",
  measurementId: "G-EXQBBRWSQV"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const appCheck = firebase.appCheck();
appCheck.activate('6Lf1b1ceAAAAAJ9_7-jNy_ZSZ-wI36q6gLQjcnmO');

const analytics = getAnalytics(app);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
