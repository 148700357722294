import { DocumentData, writeBatch } from "firebase/firestore"; 

import { createMachine, assign, interpret, spawn, ActorRefWithDeprecatedState, Interpreter } from 'xstate';

import { DateTime } from 'luxon';

import { db, auth } from '../../../firebase';

import { store } from '../../core/store';

import { DogData } from "../dog/services/dogDTO";
import { createEditDogContextFromDogData, EditDogActor, spawnEditDog } from "../dog/machines/editDog";

type ListDogsEvents = 
  | { type: 'RETRY' };


export type DogActorRef = {
  id: string;
  ref: EditDogActor;
};
interface ListDogsContext {
  dogs: DogActorRef[];
}

const listDogsService = (context: ListDogsContext): Promise<DogData[]> => new Promise((resolve, reject) => {
  const userId = auth.currentUser?.uid;
  db.collection(`users/${userId}/dogs`)
    .get()
    .then((querySnapshot) => {
      const dogs: DogData[] = [];
      querySnapshot.forEach((doc) => {
        const dog: any = doc.data();
        dogs.push({
          id: doc.id,
          ...dog,
        })
      });
      dogs.sort((a, b) => (a.createdAt?.toDate().valueOf() ?? 0) - (b.createdAt?.toDate().valueOf() ?? 0));
      resolve(dogs);
    })
    .catch((error) => {
      reject(error);
    });
});

export const listDogsMachine = createMachine<ListDogsContext, ListDogsEvents>({
  id: 'list-dogs',

  initial: 'getDogs',

  context: {
    dogs: [],
  },

  states: {
    getDogs: {
      invoke: {
        src: (context, event) => listDogsService(context),
        onDone: {
          target: 'view',
          actions: ['setDogs']
        },
        onError: {
          target: 'failed'
        }
      }
    },
    view: {},
    failed: {
      on: {
        RETRY: 'getDogs'
      }
    },
  }
},
{
  actions: {
    setDogs: assign({
      dogs: (context, event: any) => {
        return event.data.map((dog: DogData) => {
          const dogContext = createEditDogContextFromDogData(dog);
          const service = spawnEditDog(dogContext);
          
          const dogId = dogContext.id;

          store.dogsContext.set(dogId, dogContext);
          store.dogsService.set(dogId, service);

          return {
            ref: service,
            id: dogId,
          }
        });
      }
    }),
  }
});
