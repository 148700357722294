import { ActionObject, createMachine, TransitionsConfig, assign } from "xstate";
import { store } from "../../../core/store";

import { createDogService } from "../services/createDog";

import {
  actions as personalActions,
  DogPersonalEvent,
} from "./actions/personal";
import { createDogPersonalInformationContext, DogPersonalInformationContext } from "./contexts/personal";
import { createEditDogContextFromCreateDog, spawnEditDog } from "./editDog";

export type CreateDogContext =
  & DogPersonalInformationContext
  & { id: string };
type CreateDogEvent =
  | DogPersonalEvent
  | { type: 'CREATE' };

const target = 'gatherData';
const commonActions: TransitionsConfig<CreateDogContext, CreateDogEvent> = {
  INPUT_NAME: {
    target,
    actions: ['setName'],
  },
  INPUT_BREED: {
    target,
    actions: ['setBreed']
  },
  INPUT_DATE_OF_BIRTH: {
    target,
    actions: ['setDateOfBirth']
  },
  INPUT_SEX: {
    target,
    actions: ['setSex']
  },
  INPUT_WEIGHT: {
    target,
    actions: ['setWeight']
  },
  INPUT_WEIGHT_UNIT: {
    target,
    actions: ['setWeightUnit']
  },
  INPUT_MICROCHIPPED: {
    target,
    actions: ['setMicrochipped']
  },
  INPUT_DEFINING_MARKINGS: {
    target,
    actions: ['setDefiningMarkings']
  },
  INPUT_CARE_INFORMATION: {
    target,
    actions: ['setCareInformation'],
  },
};

const actions = Object.entries(personalActions).reduce((acc, [ key, value ]) => {
  acc[key] = value as ActionObject<CreateDogContext, CreateDogEvent>;
  return acc;
}, {} as { [key in string]: ActionObject<CreateDogContext, CreateDogEvent> });

const createCreateDogContext = () =>
  createDogPersonalInformationContext();

export const createDogMachine = createMachine<CreateDogContext, CreateDogEvent>({
  id: 'create-dog',

  initial: 'idle',

  context: {
    id: '',
    ...createCreateDogContext()
  },

  states: {
    idle: {
      on: {
        ...commonActions
      }
    },
    gatherData: {
      on: {
        ...commonActions,
        CREATE: 'creating',
      }
    },
    creating: {
      invoke: {
        src: (context, event) => createDogService(context, event),
        onDone: {
          target: 'created',
          actions: ['spawnEditDog', 'setId']
        },
        onError: 'gatherData',
      }
    },
    created: {},
  },
},
{
  actions: {
    ...actions,
    spawnEditDog: (context, event: any) => {
      const dogContext = createEditDogContextFromCreateDog({ ...context, id: event.data });
      const service = spawnEditDog(dogContext);
      
      const dogId = dogContext.id;

      store.dogsContext.set(dogId, dogContext);
      store.dogsService.set(dogId, service);
    },
    setId: assign({
      id: (context, event: any) => event.data
    })
  },
});
