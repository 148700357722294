import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
} from "react-router-dom";

import { SnackbarProvider } from 'notistack';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { auth } from './firebase';

import './App.css';

import { Router } from './components/core/router';

let theme = createTheme({
  palette: {
    primary: {
      main: '#678D58',
    },
    secondary: {
      main: '#DD9787',
    },
  },
})

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <ThemeProvider theme={ theme }>
      <SnackbarProvider>
        <BrowserRouter>
          <Router authenticated={ authenticated } />
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
