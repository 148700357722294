import React, { useEffect } from 'react';

import { useActor, useMachine } from '@xstate/react';

import { useNavigate } from "react-router-dom";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import ImageIcon from '@mui/icons-material/Image';
import Paper from '@mui/material/Paper';
import CardActionArea from '@mui/material/CardActionArea';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';

export const ListDogCardSkeleton: React.FC = () => {
  const autoWidthCard = useMediaQuery('(max-width:800px)');

  const avatarSize = autoWidthCard ? 30 : 75;

  return (
    <Card
      variant="outlined"
      sx={{
        width: autoWidthCard ? '100%' : 500,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: autoWidthCard ? 140 : 350,
          height: autoWidthCard ? 100 : 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Skeleton
          variant="circular"
          height={avatarSize}
          width={avatarSize}
        />
      </Box>
      <Skeleton
        variant="rectangular" 
        height='100%'
        width='100%'
      />
    </Card>
  );
}
