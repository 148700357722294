import firebase from 'firebase/compat/app';
import { Timestamp } from "firebase/firestore";

import { auth, db } from "../../../../firebase";
import { DogBaseContext } from '../machines/contexts/base';
import { ImageContext } from '../machines/contexts/image';
import { uploadFile } from './uploadFile';

type UploadDogProfilePictureContext = DogBaseContext & { profilePicture: ImageContext }

export const uploadDogProfilePicture = ({
  id,
  profilePicture,
}: UploadDogProfilePictureContext, { file }: { file: File }): Promise<ImageContext> => new Promise((resolve, reject) => {
  const userId = auth.currentUser?.uid;

  uploadFile(`users/${userId}/dogs/${id}/profile`, file, { cacheControl: 'private, max-age=300' })
    .then(resolve)
    .catch(reject);
});
