import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


import { EditDogLayout } from './components/layout';
import { DogMultiFileInputForm, DogMultiFileInputFormProps } from '../forms/multiFileInput';
import { useParams } from 'react-router-dom';
import { EditDogActor } from '../machines/editDog';
import { store } from '../../../core/store';
import { useActor } from '@xstate/react';

export const EditDogOwnerDocuments: React.FC = () => {
  const params = useParams();

  const service: EditDogActor = store.dogsService.get(params.dogId ?? '');
  const [state] = useActor(service);
  const send = service.send;

  const files = state.context.ownerDocuments
    .filter(od => od.url !== null)
    .map(od => ({ name: od.name, id: od.ref , url: od.url }));

  const fileInputProps: DogMultiFileInputFormProps = {
    files,
    onFileAdd: (file: File) => send('INPUT_OWNER_DOCUMENT', { file }),
    onFileDelete: (id: string) => send('DELETE_OWNER_DOCUMENT', { ref: id }),
    uploadingFile: ['attachments.uploadOwnerDocument', 'attachments.updateDog'].some(state.matches),
    deletingFile: ['attachments.deleteOwnerDocument', 'attachments.updateDog'].some(state.matches),
    addText: 'Add Document',
  };

  return (
    <EditDogLayout>
      <Grid
        container
        spacing={4}
      >
        <Grid item lg={6} md>
          <Grid item sx={{ mb: 4 }}>
            <Typography variant='h4'>Owner Documents</Typography>
          </Grid>
          <DogMultiFileInputForm { ...fileInputProps } />
        </Grid>
      </Grid>
    </EditDogLayout>
  );
}
