import { assign, ActionObject, createMachine, TransitionsConfig } from "xstate";
import { CardAccess, store } from "../../../core/store";
import { createEditPublicDogCardContextFromPublicCardData, EditPublicDogCardActor, spawnEditPublicDogCard } from "../card/machines/editPublicCard";
import { DogCardData } from "../card/services/publicCardDTO";
import { listPublicCardsService } from "../services/listCards";

interface ListDogCardsMachineProps {
  dogId: string;
}

export type PublicDogCardActorRef = {
  id: string;
  ref: EditPublicDogCardActor;
};

export type ListDogCardContext =
  & { dogId: string; }
  & { publicCards: PublicDogCardActorRef[] };

type ListDogCardEvent =
  | { type: 'RETRY' };

export const createListDogCardsMachine = ({ dogId }: ListDogCardsMachineProps) =>
  createMachine<ListDogCardContext, ListDogCardEvent>({
    id: 'list-dog-cards',

    initial: 'fetchingPublicCards',

    context: {
      dogId,
      publicCards: [],
    },

    states: {
      fetchingPublicCards: {
        invoke: {
          src: (context, event) => listPublicCardsService(context, event),
          onDone: {
            target: 'view',
            actions: ['setPublicCards']
          },
          onError: 'failed'
        }
      },
      view: {},
      failed: {
        on: {
          RETRY: 'fetchingPublicCards'
        }
      }
    },
  },
  {
    actions: {
      setPublicCards: assign({
        publicCards: (context, event: any) => {
          return event.data.map((cardData: DogCardData) => {
            const cardId = cardData.id;
            const cardContext = createEditPublicDogCardContextFromPublicCardData(cardData);
            const editCardMachine = spawnEditPublicDogCard(cardContext);
            store.cardsContext.set(cardId, CardAccess.Public, cardContext);
            store.cardsService.set(cardId, CardAccess.Public, editCardMachine);
            return {
              id: cardData.id,
              ref: editCardMachine,
            };
          })
        }
      })
    },
  });
