import { DateTime } from "luxon";
import { assign } from "xstate";

import { DogSex, Weight } from "../../enums";
import { DogPersonalInformationContext } from "../contexts/personal";

export type INPUT_NAME = { type: 'INPUT_NAME', name: string };
export type INPUT_BREED = { type: 'INPUT_BREED', breed: string };
export type INPUT_DATE_OF_BIRTH = { type: 'INPUT_DATE_OF_BIRTH', dateOfBirth: DateTime };
export type INPUT_SEX = { type: 'INPUT_SEX', sex: DogSex };
export type INPUT_WEIGHT = { type: 'INPUT_WEIGHT', value: number };
export type INPUT_WEIGHT_UNIT = { type: 'INPUT_WEIGHT_UNIT', unit: Weight };
export type INPUT_MICROCHIPPED = { type: 'INPUT_MICROCHIPPED', microchipped: boolean };
export type INPUT_DEFINING_MARKINGS = { type: 'INPUT_DEFINING_MARKINGS', definingMarkings: string };
export type INPUT_CARE_INFORMATION = { type: 'INPUT_CARE_INFORMATION', careInformation: string };
export type DogPersonalEvent =
  | INPUT_NAME
  | INPUT_BREED
  | INPUT_DATE_OF_BIRTH
  | INPUT_SEX
  | INPUT_WEIGHT
  | INPUT_WEIGHT_UNIT
  | INPUT_MICROCHIPPED
  | INPUT_DEFINING_MARKINGS
  | INPUT_CARE_INFORMATION;

export const setName = assign<DogPersonalInformationContext, DogPersonalEvent>({
  name: (context, event) => (event as INPUT_NAME).name
});
export const setBreed = assign({
  breed: (context, event) => (event as INPUT_BREED).breed
});
export const setDateOfBirth = assign({
  dateOfBirth: (context, event) => (event as INPUT_DATE_OF_BIRTH).dateOfBirth
});
export const setSex = assign({
  sex: (context, event) => (event as INPUT_SEX).sex
});
export const setWeight = assign({
  weight: (context: any, event) => ({
    value: (event as INPUT_WEIGHT).value,
    unit: context.weight.unit
  })
});
export const setWeightUnit = assign({
  weight: (context: any, event) => ({
    value: context.weight.value,
    unit: (event as INPUT_WEIGHT_UNIT).unit,
  })
});
export const setMicrochipped = assign({
  microchipped: (context, event) => (event as INPUT_MICROCHIPPED).microchipped
});
export const setDefiningMarkings = assign({
  definingMarkings: (context, event) => (event as INPUT_DEFINING_MARKINGS).definingMarkings
});
export const setCareInformation = assign({
  careInformation: (context, event) => (event as INPUT_CARE_INFORMATION).careInformation
});

export const actions = {
  setName,
  setBreed,
  setDateOfBirth,
  setSex,
  setWeight,
  setWeightUnit,
  setMicrochipped,
  setDefiningMarkings,
  setCareInformation,
};
