import { auth, storage } from "../../../../firebase";
import { DogBaseContext } from '../machines/contexts/base';
import { FileContext } from "../machines/contexts/file";
import { ImageContext } from '../machines/contexts/image';

type DeleteDogOwnerDocumentsContext = DogBaseContext & { ownerDocuments: FileContext[] };

export const deleteDogOwnerDocument = ({ id, ownerDocuments }: DeleteDogOwnerDocumentsContext, { ref }: { ref: string }): Promise<FileContext[]> =>
  new Promise((resolve, reject) => {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(ref);
    fileRef.delete()
      .then(() => {
        resolve(ownerDocuments.filter(od => od.ref && od.ref !== ref));
      })
      .catch(reject);
  });
