import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { Settings } from '../index';

// export const createSettingsRoutes = (authenticated: boolean) => [
//   {
//     path: '/settings',
//     element: authenticated ? <Settings /> : <Navigate to="/login" />,
//   },
// ];

export const settingsRoutes = () => {
  return (
    <Route path="/settings" element={ <Settings /> } />
  )
}
