import { ActionObject, createMachine, interpret, TransitionsConfig, assign, Interpreter } from "xstate";
import { DateTime } from "luxon";

import { store } from "../../../core/store";
import { CreateDogContext } from "./createDog";

import { DogBaseContext } from "./contexts/base";
import { createImageContext, ImageContext } from "./contexts/image";
import { createFileContext, FileContext } from "./contexts/file";
import { createDogPersonalInformationContext, DogPersonalInformationContext } from "./contexts/personal";
import { createDogOwnerContactInformationContext, DogOwnerContactInformationContext } from "./contexts/owner";
import { createDogVeterinarianInformationContext, DogVeterinarianInformationContext } from "./contexts/veterinarian";

import {
  actions as dogPersonalActions,
  DogPersonalEvent,
} from "./actions/personal";
import {
  actions as dogOwnerContactActions,
  DogOwnerContactInformationEvent,
} from './actions/owner';
import {
  actions as dogVeterinarianActions,
  DogVeterinarianInformationEvent
} from "./actions/veterinarian";

import { DogData } from "../services/dogDTO";
import { getFile, getFiles, getImage } from "../services/getFile";
import { uploadDogProfilePicture } from "../services/uploadDogProfilePicture";
import { updateDogService } from "../services/updateDog";
import { cleanupDogProfilePictures } from "../services/cleanupDogProfilePictures";
import { deleteDogService } from "../services/deleteDog";
import { deleteDogProfilePicture } from "../services/deleteDogProfilePicture";
import { uploadDogOwnerDocument } from "../services/uploadDogOwnerDocument";
import { deleteDogOwnerDocument } from "../services/deleteDogOwnerDocument";
import { uploadDogMedicalHistoryDocumetn } from "../services/uploadDogMedicalHistoryDocument";
import { deleteDogMedicalHistoryDocument } from "../services/deleteDogMedicalHistoryDocument";

export type EditDogContext =
  & DogBaseContext 
  & { profilePicture: ImageContext }
  & { ownerDocuments: FileContext[] }
  & { medicalHistoryDocuments: FileContext[] }
  & DogPersonalInformationContext
  & DogOwnerContactInformationContext
  & DogVeterinarianInformationContext;
  
type INPUT_PROFILE_PICTURE = { type: 'INPUT_PROFILE_PICTURE', file: File };
type INPUT_OWNER_DOCUMENT = { type: 'INPUT_OWNER_DOCUMENT', file: File };
type DELETE_OWNER_DOCUMENT = { type: 'DELETE_OWNER_DOCUMENT', ref: string };
type INPUT_MEDICAL_HISTORY_DOCUMENT = { type: 'INPUT_MEDICAL_HISTORY_DOCUMENT', file: File };
type DELETE_MEDICAL_HISTORY_DOCUMENT = { type: 'DELETE_MEDICAL_HISTORY_DOCUMENT', ref: string };
export type EditDogEvent =
  | DogPersonalEvent
  | DogOwnerContactInformationEvent
  | DogVeterinarianInformationEvent
  | INPUT_PROFILE_PICTURE
  | INPUT_OWNER_DOCUMENT
  | DELETE_OWNER_DOCUMENT
  | INPUT_MEDICAL_HISTORY_DOCUMENT
  | DELETE_MEDICAL_HISTORY_DOCUMENT
  | { type: 'DELETE_PROFILE_PICTURE' }
  | { type: 'UPDATE' }
  | { type: 'DISCARD_CHANGES' }
  | { type: 'DELETE' }
  | { type: 'CONFIRM_DELETE' }
  | { type: 'CANCEL_DELETE' };

const target = 'editing';
const personalActions: TransitionsConfig<EditDogContext, EditDogEvent> = {
  INPUT_NAME: {
    target,
    actions: ['setName'],
  },
  INPUT_BREED: {
    target,
    actions: ['setBreed']
  },
  INPUT_DATE_OF_BIRTH: {
    target,
    actions: ['setDateOfBirth']
  },
  INPUT_SEX: {
    target,
    actions: ['setSex']
  },
  INPUT_WEIGHT: {
    target,
    actions: ['setWeight']
  },
  INPUT_WEIGHT_UNIT: {
    target,
    actions: ['setWeightUnit']
  },
  INPUT_MICROCHIPPED: {
    target,
    actions: ['setMicrochipped']
  },
  INPUT_DEFINING_MARKINGS: {
    target,
    actions: ['setDefiningMarkings']
  },
};
const ownerContactActions: TransitionsConfig<EditDogContext, EditDogEvent> = {
  INPUT_OWNER_FIRST_NAME: {
    target,
    actions: ['setOwnerFirstName'],
  },
  INPUT_OWNER_LAST_NAME: {
    target,
    actions: ['setOwnerLastName']
  },
  INPUT_OWNER_EMAIL: {
    target,
    actions: ['setOwnerEmail']
  },
  INPUT_OWNER_PHONE_NUMBER: {
    target,
    actions: ['setOwnerPhoneNumber']
  },
  INPUT_OWNER_ADDRESS_1: {
    target,
    actions: ['setOwnerAddress1']
  },
  INPUT_OWNER_ADDRESS_2: {
    target,
    actions: ['setOwnerAddress2']
  },
  INPUT_OWNER_CITY: {
    target,
    actions: ['setOwnerCity']
  },
  INPUT_OWNER_STATE: {
    target,
    actions: ['setOwnerState']
  },
  INPUT_OWNER_ZIP_CODE: {
    target,
    actions: ['setOwnerZipCode']
  },
};
const veterinarianActions: TransitionsConfig<EditDogContext, EditDogEvent> = {
  INPUT_VETERINARIAN_NAME: {
    target,
    actions: ['setVeterinarianName'],
  },
  INPUT_VETERINARIAN_WEBSITE: {
    target,
    actions: ['setVeterinarianWebsite']
  },
  INPUT_VETERINARIAN_EMAIL: {
    target,
    actions: ['setVeterinarianEmail']
  },
  INPUT_VETERINARIAN_PHONE_NUMBER: {
    target,
    actions: ['setVeterinarianPhoneNumber']
  },
  INPUT_VETERINARIAN_ADDRESS_1: {
    target,
    actions: ['setVeterinarianAddress1']
  },
  INPUT_VETERINARIAN_ADDRESS_2: {
    target,
    actions: ['setVeterinarianAddress2']
  },
  INPUT_VETERINARIAN_CITY: {
    target,
    actions: ['setVeterinarianCity']
  },
  INPUT_VETERINARIAN_STATE: {
    target,
    actions: ['setVeterinarianState']
  },
  INPUT_VETERINARIAN_ZIP_CODE: {
    target,
    actions: ['setVeterinarianZipCode']
  },
};
const careInformationActions: TransitionsConfig<EditDogContext, EditDogEvent> = {
  INPUT_CARE_INFORMATION: {
    target,
    actions: ['setCareInformation'],
  },
};

const actions = [
  ...Object.entries(dogPersonalActions),
  ...Object.entries(dogOwnerContactActions),
  ...Object.entries(dogVeterinarianActions),
].reduce((acc, [ key, value ]) => {
  acc[key] = value as ActionObject<EditDogContext, EditDogEvent>;
  return acc;
}, {} as { [key in string]: ActionObject<EditDogContext, EditDogEvent> });

export const createEditDogContextFromCreateDog = (context: CreateDogContext): EditDogContext => ({
  ...context,
  ...createDogOwnerContactInformationContext(),
  ...createDogVeterinarianInformationContext(),
  profilePicture: createImageContext(undefined),
  ownerDocuments: [],
  medicalHistoryDocuments: [],
});
export const createEditDogContextFromDogData = (dog: DogData): EditDogContext =>
  Object.assign(
    createDogPersonalInformationContext(),
    createDogOwnerContactInformationContext(),
    createDogVeterinarianInformationContext(),
    { ...dog },
    { profilePicture: createImageContext(dog.profilePicture) },
    { ownerDocuments: (dog.ownerDocuments ?? []).map(od => createFileContext(od)) },
    { medicalHistoryDocuments: (dog.medicalHistoryDocuments ?? []).map(mhd => createFileContext(mhd)) },
    { dateOfBirth: (dog.dateOfBirth ? DateTime.fromJSDate(dog.dateOfBirth.toDate()) : null) }
  );

export type EditDogActor = Interpreter<EditDogContext, any, EditDogEvent, {
  value: any;
  context: EditDogContext;
}>;

export const createEditDogMachine = (context: EditDogContext) => {
  const machineId = `edit-dog-${context.id}`;
  return createMachine<EditDogContext, EditDogEvent>({
    id: machineId,

    type: 'parallel',

    context,

    states: {
      data: {
        initial: 'view',
        states: {
          view: {
            on: {
              ...personalActions,
              ...ownerContactActions,
              ...veterinarianActions,
              ...careInformationActions,
              DELETE: 'confirmDelete',
            }
          },
          editing: {
            on: {
              ...personalActions,
              ...ownerContactActions,
              ...veterinarianActions,
              ...careInformationActions,
              DISCARD_CHANGES: {
                target: 'view',
                actions: ['discardChanges']
              },
              UPDATE: 'updating',
            }
          },
          updating: {
            invoke: {
              src: (context, event) => updateDogService(context, event),
              onDone: '.success',
              onError: '.failed',
            },
            initial: 'inProgress',
            states: {
              inProgress: {},
              success: {
                after: {
                  1: {
                    target: `#${machineId}.data.view`,
                    actions: ['resetContext']
                  }
                }
              },
              failed: { after: { 1: { target: `#${machineId}.data.editing` } } },
            }
          },
          confirmDelete: {
            on: {
              CONFIRM_DELETE: 'deleting',
              CANCEL_DELETE: 'view',
            }
          },
          deleting: {
            invoke: {
              src: (context, event) => deleteDogService(context, event),
              onDone: '.success',
              onError: '.failed',
            },
            initial: 'inProgress',
            states: {
              inProgress: {},
              success: { after: { 1: { target: `#${machineId}.data.view` } } },
              failed: { after: { 1: { target: `#${machineId}.data.view` } } },
            }
          },
        }
      },
      attachments: {
        initial: 'downloadProfilePicture',
        states: {
          idle: {
            on: {
              INPUT_PROFILE_PICTURE: 'uploadProfilePicture',
              DELETE_PROFILE_PICTURE: 'deleteProfilePicture',
              INPUT_OWNER_DOCUMENT: 'uploadOwnerDocument',
              DELETE_OWNER_DOCUMENT: 'deleteOwnerDocument',
              INPUT_MEDICAL_HISTORY_DOCUMENT: 'uploadMedicalHistoryDocument',
              DELETE_MEDICAL_HISTORY_DOCUMENT: 'deleteMedicalHistoryDocument',
            }
          },
          deleteProfilePicture: {
            invoke: {
              src: (context, event) => deleteDogProfilePicture(context, event),
              onDone: {
                target: 'updateDog',
                actions: ['setProfilePicture']
              },
              onError: 'idle'
            }
          },
          uploadProfilePicture: {
            invoke: {
              src: (context, event) => uploadDogProfilePicture(context, event as INPUT_PROFILE_PICTURE),
              onDone: {
                target: 'updateProfilePictureForDog',
                actions: ['setProfilePicture']
              },
              onError: 'idle'
            }
          },
          downloadProfilePicture: {
            invoke: {
              src: (context, event) => getImage(context.profilePicture.ref),
              onDone: {
                target: 'downloadMedicalHistoryDocuments',
                actions: ['setProfilePicture']
              },
              onError: 'idle'
            }
          },
          deleteOwnerDocument: {
            invoke: {
              src: (context, event) => deleteDogOwnerDocument(context, event as DELETE_OWNER_DOCUMENT),
              onDone: {
                target: 'updateDog',
                actions: ['setOwnerDocuments']
              },
              onError: 'idle'
            }
          },
          downloadOwnerDocuments: {
            invoke: {
              src: (context, event) => getFiles((context.ownerDocuments ?? []).map(od => od.ref ?? '')),
              onDone: {
                target: 'idle',
                actions: ['setOwnerDocuments']
              },
              onError: 'idle'
            }
          },
          uploadOwnerDocument: {
            invoke: {
              src: (context, event) => uploadDogOwnerDocument(context, event as INPUT_OWNER_DOCUMENT),
              onDone: {
                target: 'updateDog',
                actions: ['setOwnerDocuments']
              },
              onError: 'idle'
            }
          },
          deleteMedicalHistoryDocument: {
            invoke: {
              src: (context, event) => deleteDogMedicalHistoryDocument(context, event as DELETE_MEDICAL_HISTORY_DOCUMENT),
              onDone: {
                target: 'updateDog',
                actions: ['setMedicalHistoryDocuments']
              },
              onError: 'idle'
            }
          },
          downloadMedicalHistoryDocuments: {
            invoke: {
              src: (context, event) => getFiles((context.medicalHistoryDocuments ?? []).map(od => od.ref ?? '')),
              onDone: {
                target: 'downloadOwnerDocuments',
                actions: ['setMedicalHistoryDocuments']
              },
              onError: 'idle'
            }
          },
          uploadMedicalHistoryDocument: {
            invoke: {
              src: (context, event) => uploadDogMedicalHistoryDocumetn(context, event as INPUT_MEDICAL_HISTORY_DOCUMENT),
              onDone: {
                target: 'updateDog',
                actions: ['setMedicalHistoryDocuments']
              },
              onError: 'idle'
            }
          },
          updateProfilePictureForDog: {
            invoke: {
              src: (context, event) => updateDogService(context, event),
              onDone: 'cleanUpProfilePictures',
              onError: 'idle',
            }
          },
          updateDog: {
            invoke: {
              src: (context, event) => updateDogService(context, event),
              onDone: 'idle',
              onError: 'idle',
            }
          },
          cleanUpProfilePictures: {
            invoke: {
              src: (context, event) => cleanupDogProfilePictures(context.profilePicture.ref),
              onDone: 'idle',
              onError: 'idle',
            }
          },
        }
      }
    },
  },
  {
    actions: {
      ...actions,
      setProfilePicture: assign({
        profilePicture: (context, event: any) => event.data,
      }),
      setOwnerDocuments: assign({
        ownerDocuments: (context, event: any) => event.data
      }),
      setMedicalHistoryDocuments: assign({
        medicalHistoryDocuments: (context, event: any) => event.data
      }),
      discardChanges: assign((context, event) =>
        Object.assign(
          { ...store.dogsContext.get(context.id) },
          { profilePicture: context.profilePicture }
        )),
      resetContext: (context, event) => {
        store.dogsContext.set(context.id, context)
      }
    },
  });
}

export const spawnEditDog = (context: EditDogContext) =>
  interpret(createEditDogMachine(context)).start();
