import React, { useState } from 'react';

import { useActor } from '@xstate/react';

import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import StyleIcon from '@mui/icons-material/Style';
import SaveIcon from '@mui/icons-material/Save';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { store } from '../../store';
import { EditDogActor } from '../../../dogs/dog/machines/editDog';
import { BaseAppBar } from './baseAppBar';
import Button from '@mui/material/Button';

type TabConfig = {
  route: string;
  name: string;
};

export const DogToolbar: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const service: EditDogActor = store.dogsService.get(params.dogId ?? '');
  const [state] = useActor(service);
  const send = service.send;

  const { id, name } = state.context;

  const isEditing = ['data.editing', 'data.updating'].some(state.matches);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClickActionMore = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const actionMenuControl = (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Button
        color="inherit"
        variant="text"
        startIcon={ <StyleIcon /> }
        onClick={() => navigate(`/dogs/${id}/cards/public`)}
      >
        Cards
      </Button>
      <IconButton
        size="large"
        aria-label="display more actions"
        edge="end"
        color="inherit"
        onClick={handleClickActionMore}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseActionMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => send('DELETE')}>Delete</MenuItem>
      </Menu>
    </Box>
  );

  const tabs: TabConfig[] = [
    {
      route: '/dogs/:dogId/personal',
      name: 'Personal Information',
    },
    {
      route: '/dogs/:dogId/contact',
      name: 'Contact Information',
    },
    {
      route: '/dogs/:dogId/veterinarian',
      name: 'Veterinarian Information',
    },
    {
      route: '/dogs/:dogId/medical',
      name: 'Medical History',
    },
    {
      route: '/dogs/:dogId/owner',
      name: 'Owner Documents',
    },
  ];

  const findActiveTab = () => tabs.findIndex(({ route }) => matchPath(route, location.pathname));
  const handlePickTab = (tab: number) => navigate(tabs[tab].route.replace(':dogId', id), { replace: true });

  const tabsControl = (
    <Tabs
      value={findActiveTab()}
      onChange={(event, tab) => {
        handlePickTab(tab);
      }}
      indicatorColor="secondary"
      textColor="inherit"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="full width tabs example"
    >
      {
        tabs.map(({ name }, index) => (
          <Tab
            key={name}
            label={name}
            id={`tab-${index}`}
            aria-controls={`full-width-tab-panel-${index}`}
          />
        ))
      }
    </Tabs>
  );

  const viewToolbar = (
    <Box>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => navigate(-1)}
          edge="start"
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          { name }
        </Typography>
        { actionMenuControl }
      </Toolbar>
      { tabsControl }
    </Box>
  );

  const editToolbar = (
    <Toolbar>
      <Typography variant="h6" noWrap component="div">
        Unsaved Changes
      </Typography>
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
        <IconButton
          sx={{
            mr: 2,
          }}
          color="inherit"
          aria-label="discard"
          onClick={() => send('DISCARD_CHANGES')}
          edge="start"
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="save"
          onClick={() => send('UPDATE')}
          edge="start"
        >
          <SaveIcon />
        </IconButton>
      </Box>
    </Toolbar>
  );

  return (
    <BaseAppBar color={isEditing ? 'secondary' : 'primary'}>
      {
        isEditing
        ? editToolbar
        : viewToolbar
      }
    </BaseAppBar>
  );
};
