import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from "react-router-dom";
import { useActor } from '@xstate/react';

import Box from '@mui/material/Box';
import { CardAccess, store } from '../../../../../core/store';
import { EditPublicDogCardActor } from '../../machines/editPublicCard';
import { DogCardPersonalKey } from '../../machines/contexts/configuration';
import { DogCardPersonalInformationConfigurationForm, DogCardPersonalInformationConfigurationFormProps, SetPersonalProps } from '../../forms/dogPersonalInformation';
import { PublicDogCardConfigurationForm, PublicDogCardConfigurationFormProps } from '../../forms/publicDogCard';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { createAction } from '../../../../../core/components/close-snack';

export const EditPublicDogCard: React.FC = () => {
  const { cardId, dogId } = useParams();
  const navigate = useNavigate();

  const service: EditPublicDogCardActor = store.cardsService.get(cardId ?? '', CardAccess.Public);
  const [state] = useActor(service);
  const send = service.send;

  const { id, name } = state.context;

  const cardName = name || id;

  const dogCardPersonalInfoProps: DogCardPersonalInformationConfigurationFormProps = {
    personal: state.context.personal,
    onSetAll: (checked: boolean) => send(
      Object.keys(state.context.personal).map(k => ({
        type: 'INPUT_PERSONAL',
        key: k as DogCardPersonalKey,
        checked,
      }))
    ),
    onSetPersonal: (props: SetPersonalProps) => send('INPUT_PERSONAL', props),
  };

  console.log(state.context)

  const publicDogCardInfoProps: PublicDogCardConfigurationFormProps = {
    ...dogCardPersonalInfoProps,
    name: state.context.name,
    onSetName: (name: string) => send('INPUT_NAME', { name })
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (state.matches('updating.success')) {
      enqueueSnackbar(`Successfully updated ${cardName}.`, {
        variant: 'success',
        action: createAction(closeSnackbar),
      });
    } else if (state.matches('updating.failed')) {
      enqueueSnackbar(`Failed to update ${cardName}. Please try again.`, {
        variant: 'error',
        action: createAction(closeSnackbar),
      });
    } else if (state.matches('deleting.success')) {
      enqueueSnackbar(`Successfully deleted ${cardName}.`, {
        variant: 'success',
        action: createAction(closeSnackbar),
      });
      navigate(-1);
    } else if (state.matches('deleting.failed')) {
      enqueueSnackbar(`Failed to delete ${cardName}. Please try again.`, {
        variant: 'error',
        action: createAction(closeSnackbar),
      });
    }
  }, [state.value]);

  return (
    <Box
      sx={{
        m: 2,
        mt: 6,
        mb: 6,
      }}
    >
      <PublicDogCardConfigurationForm { ...publicDogCardInfoProps } />

      <Dialog
        open={ state.matches('confirmDelete') }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete { cardName }?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will remove { cardName } from your public cards list. 
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description-2"
            sx={{
              fontWeight: 600,
            }}
          >
            This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => send('CANCEL_DELETE') }>Cancel</Button>
          <Button onClick={ () => send('CONFIRM_DELETE') }>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
