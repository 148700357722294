import React, { useEffect } from 'react';

import { useParams } from "react-router-dom";
import { useMachine } from '@xstate/react';

import Dialog from '@mui/material/Dialog';

import { createDisaplyPublicCardMachine } from './machines/displayCard';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const textWidget = (label: string, text: string) => ([
  (<Typography key={ label }>
    { label }:
  </Typography>),
  (<Typography key={ text }>
    { text }
  </Typography>)
]);

export const PublicCard = () => {
  const { cardId } = useParams();

  const [state, send] = useMachine(() => createDisaplyPublicCardMachine(cardId || ''));

  console.log(state.context)

  const {
    name,
    breed,
    dateOfBirth,
    sex,
    weight,
    microchipped,
    definingMarkings,
    careInformation,
  } = state.context;

  return (
    <Dialog
      fullScreen
      open={true}
    >
      <Box
        sx={{
          mt: 5,
          mb: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: 'fit-content(50ch) fit-content(50ch)',
          }}
        >
          { name && textWidget('Name', name) }
          { breed && textWidget('Breed', breed) }
          { dateOfBirth && textWidget('Date of Birth', dateOfBirth.toFormat('EEE, LLL dd, yyyy')) }
          { microchipped !== undefined && textWidget('Microchipped', microchipped ? 'Yes' : 'No') }
          { sex !== undefined && textWidget('Sex', sex) }
          { weight !== undefined && textWidget('Weight', `${weight.value} ${weight.unit}`) }
          { definingMarkings !== undefined && textWidget('Defining Markings', definingMarkings) }
          { careInformation !== undefined && textWidget('Care Information', careInformation) }
        </Box>
      </Box>
    </Dialog>
  );
}
