import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { PublicCard } from '../public';

export const cardsRoutes = () => {
  return (
    <Route path="/c/:cardId" element={ <PublicCard /> } />
  )
}
