import { assign, ActionObject, createMachine, TransitionsConfig } from "xstate";
import { auth } from "../../../../../firebase";
import { CardAccess, store } from "../../../../core/store";
import { createDogCardPublicService } from "../services/createDogCardService";
import { DogCardCheckContext, DogCardEntitiesContext, DogCardShareableContext } from "./contexts/base";
import { createDogCardPersonalContext, DogCardPersonalContext, DogCardPersonalKey } from "./contexts/configuration";
import { createEditPublicDogCardContextFromCreateCard, spawnEditPublicDogCard } from "./editPublicCard";

interface CreateDogCardMachineProps {
  dogId: string;
}

export interface DogCardPublic {
  personal: DogCardPersonalContext;
  name: string;
}

export type CreateDogCardContext =
  & { id: string }
  & DogCardEntitiesContext
  & DogCardShareableContext
  & DogCardPublic;

type INPUT_PERSONAL = { type: 'INPUT_PERSONAL', key: DogCardPersonalKey, checked: boolean };
type INPUT_NAME = { type: 'INPUT_NAME', name: string };
type CreateDogCardEvent =
  | { type: 'SET_PUBLIC_ACCESS' }
  | { type: 'SET_PRIVATE_ACCESS' }
  | INPUT_PERSONAL
  | INPUT_NAME
  | { type: 'CREATE' };

export const createCreateDogCardMachine = ({ dogId }: CreateDogCardMachineProps) =>
  createMachine<CreateDogCardContext, CreateDogCardEvent>({
    id: 'create-dog-card',

    initial: 'setup',

    context: {
      id: '',
      dogId,
      userId: '',
      url: '',
      personal: createDogCardPersonalContext(),
      name: '',
    },

    states: {
      setup: {
        always: [
          { target: 'public', actions: ['setEntities'] }
        ]
      },
      public: {
        on: {
          SET_PRIVATE_ACCESS: 'private',
          INPUT_PERSONAL: {
            actions: ['setPersonal']
          },
          INPUT_NAME: {
            actions: ['setName']
          },
          CREATE: 'creatingPublic'
        }
      },
      private: {
        on: {
          SET_PUBLIC_ACCESS: 'public',
        }
      },
      creatingPublic: {
        invoke: {
          src: (context, event) => createDogCardPublicService(context, event),
          onDone: {
            target: 'created',
            actions: ['setUrl', 'setId', 'spawnPublicCard']
          },
          onError: 'public',
        }
      },
      created: {},
    },
  },
  {
    actions: {
      setId: assign({
        id: (context, event: any) => event.data.id,
      }),
      setUrl: assign({
        url: (context, event: any) => event.data.url,
      }),
      setEntities: assign({
        userId: (context, event) => auth.currentUser?.uid ?? '',
      }),
      setPersonal: assign({
        personal: (context, event) => {
          const e = event as INPUT_PERSONAL;
          return { ...context.personal, [e.key]: e.checked };
        }
      }),
      setName: assign({
        name: (context, event) => (event as INPUT_NAME).name
      }),
      spawnPublicCard: (context, event: any) => {
        const cardContext = createEditPublicDogCardContextFromCreateCard({ ...context, ...event.data });
        const service = spawnEditPublicDogCard(cardContext);
        
        const cardId = cardContext.id;

        store.cardsContext.set(cardId, CardAccess.Public, cardContext);
        store.cardsService.set(cardId, CardAccess.Public, service);
      }
    },
  });
