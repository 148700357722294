import { auth, storage } from "../../../../firebase";
import { DogBaseContext } from '../machines/contexts/base';
import { ImageContext } from '../machines/contexts/image';

export const deleteDogProfilePicture = ({ id }: DogBaseContext, event: any): Promise<ImageContext> =>
  new Promise((resolve, reject) => {
    const userId = auth.currentUser?.uid;

    const storageRef = storage.ref();
    const imageRef = storageRef.child(`users/${userId}/dogs/${id}/profile`);
    imageRef.listAll()
      .then(res => {
        Promise.all(res.items.map(f => f.delete()))
          .then(() => resolve({
            ref: undefined,
            url: null,
          }))
          .catch(reject);
      })
      .catch(reject)
  });
