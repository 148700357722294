import React from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DogCardPersonalKey, DogCardPersonalOrdering } from '../machines/contexts/configuration';
import Box from '@mui/material/Box';

export type SetPersonalProps = { key: DogCardPersonalKey, checked: boolean };
export interface DogCardPersonalInformationConfigurationFormProps {
  // careInformation: string;
  personal: { [key in DogCardPersonalKey]: boolean };
  onSetAll: (checked: boolean) => void;
  onSetPersonal: (props: SetPersonalProps) => void;
  // onCareInformationChange: (careInformation: string) => void;
}

export const DogCardPersonalInformationConfigurationForm: React.FC<DogCardPersonalInformationConfigurationFormProps> = ({
  personal,
  onSetAll,
  onSetPersonal,
  // careInformation,
  // onCareInformationChange,
}) => {
  const checked = Object.values(personal).every(value => value === true);
  const unchecked = Object.values(personal).every(value => value === false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
        label="Dog Information"
        control={
          <Checkbox
            checked={checked}
            indeterminate={!checked && !unchecked}
            onChange={(e) => onSetAll(e.target.checked)}
          />
        }
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {
          DogCardPersonalOrdering.map(([key, label]) => (
            <FormControlLabel
              key={key}
              label={label}
              control={
                <Checkbox
                  checked={personal[key]}
                  onChange={(e) => {
                    onSetPersonal({ key, checked: e.target.checked })
                  }}
                />
              }
            />
          ))
        }
      </Box>
    </Box>
  );
}
