import { getBlob } from 'firebase/storage';
import { storage } from "../../../../firebase";
import { FileContext } from "../machines/contexts/file";
import { ImageContext } from "../machines/contexts/image";

export const getImage = (ref?: string): Promise<ImageContext> =>
  new Promise((resolve, reject) => {
    if (!ref) {
      reject('No ref to fetch.');
    } else {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(ref);
      imageRef.getDownloadURL()
        .then(url => {
          resolve({
            ref,
            url,
          });
        })
        .catch(error => {
          reject(error);
        });
    }
  });

const getFileBlob = (url: string): Promise<Blob> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };
    xhr.send();
  });

export const getFile = (ref?: string): Promise<FileContext> =>
  new Promise((resolve, reject) => {
    if (!ref) {
      reject('No ref to fetch.');
    } else {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(ref);
      fileRef.getDownloadURL()
       .then(url => {
          resolve({
            ref,
            name: fileRef.name,
            url,
          });
        })
        .catch(error => {
          reject(error);
        });
    }
  });

export const getFiles = (refs: string[]): Promise<FileContext[]> =>
  new Promise((resolve, reject) => {
    Promise.all(refs.map(getFile))
      .then(resolve)
      .catch(reject);
  });
