import React from 'react';

import { useNavigate, useLocation, matchPath } from "react-router-dom";

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PetsIcon from '@mui/icons-material/Pets';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import useMediaQuery from '@mui/material/useMediaQuery';

type RouteConfig = {
  name: string;
  route: string;
  icon: any;
}

interface AppDrawerProps {
  open: boolean;
  handleSetOpen: (open: boolean) => void;
}

const drawerWidth = 240;

export const AppDrawer: React.FC<AppDrawerProps> = ({
  open,
  handleSetOpen,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const smallScreen = useMediaQuery('(max-width:800px)');

  const handleNavigate = (route: string) => {
    navigate(route);
    handleSetOpen(false);
  }

  const isOnRoute = (route: string) => matchPath(route, location.pathname);

  const mainRoutes: RouteConfig[] = [
    {
      name: 'Home',
      route: '/dogs',
      icon: (props: any) => <PetsIcon { ...props } />
    }
  ];
  const bottomRoutes: RouteConfig[] = [
    {
      name: 'Settings',
      route: '/settings',
      icon: (props: any) => <SettingsIcon { ...props } />
    }
  ];

  const routeItem = ({ name, route, icon }: RouteConfig) => {
    const onRoute = isOnRoute(route);
    return (
      <ListItem
        button
        key={ name }
        onClick={() => handleNavigate(route)}
      >
        <ListItemIcon>
          { icon({ sx: { color: onRoute ? 'primary.main' : 'text.secondary' } }) }
        </ListItemIcon>
        <Typography
          sx={{
            color: onRoute ? 'primary.main' : 'text.secondary',
            fontWeight: onRoute ? 600 : 400,
          }}
        >
          { name }
        </Typography>
      </ListItem>
    )
  };

  return (
    <Drawer
      open={ open }
      onClose={() => handleSetOpen(false)}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant={ smallScreen ? "temporary" : "permanent" }
      anchor="left"
    >
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Dog Card
        </Typography>
      </Toolbar>
      <Divider />
      <Box sx={{
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'space-between',
      }}>
        <List>
          {
            mainRoutes.map(routeItem)
          }
        </List>
        <List>
          <Divider />
          {
            bottomRoutes.map(routeItem)
          }
        </List>
      </Box>
    </Drawer>
  );
};
