import React from 'react';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { BaseAppBar } from './baseAppBar';

interface AppBaseToolbarProps {
  title: string;
  handleToggleDrawer: () => void;
}

export const AppBaseToolbar: React.FC<AppBaseToolbarProps> = ({
  title,
  handleToggleDrawer
}) => {
  const smallScreen = useMediaQuery('(max-width:800px)');

  return (
    <BaseAppBar>
      <Toolbar>
        {
          smallScreen
            ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => handleToggleDrawer()}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            )
            : null
        }
        <Typography variant="h6" noWrap component="div">
          { title }
        </Typography>
      </Toolbar>
    </BaseAppBar>
  );
};
