import React from 'react';

import { useActor } from '@xstate/react';

import { useParams } from "react-router-dom";

import Grid from '@mui/material/Grid';

import { DateTime } from 'luxon';

import { DogProfilePicture, DogProfilePictureProps } from '../forms/profilePicture';
import { DogInformationForm, DogInformationFormProps } from '../forms/dogInformation';
import { DogSex, Weight } from '../enums';
import { EditDogLayout } from './components/layout';
import { EditDogActor } from '../machines/editDog';
import { store } from '../../../core/store';

export const EditDogPersonalInformation = () => {
  const params = useParams();

  const service: EditDogActor = store.dogsService.get(params.dogId ?? '');
  const [state] = useActor(service);
  const send = service.send;

  const dogProfilePictureProps: DogProfilePictureProps = {
    url: state.context.profilePicture.url,
    isLoading: !state.matches('attachments.idle'),
    onFileChange: (file) => send('INPUT_PROFILE_PICTURE', { file }),
    onFileDelete: () => send('DELETE_PROFILE_PICTURE')
  };

  const dogInfoProps: DogInformationFormProps = {
    onNameChange: (name: string) => send('INPUT_NAME', { name }),
    onBreedChange: (breed: string) => send('INPUT_BREED', { breed }),
    onDateOfBirthChange: (dateOfBirth: DateTime | null) => send('INPUT_DATE_OF_BIRTH', { dateOfBirth }),
    onSexChange: (sex: DogSex) => send('INPUT_SEX', { sex }),
    onWeightValueChange: (value: number) => send('INPUT_WEIGHT', { value }),
    onWeightUnitChange: (unit: Weight) => send('INPUT_WEIGHT_UNIT', { unit }),
    onMicrochippedChange: (microchipped: boolean) => send('INPUT_MICROCHIPPED', { microchipped }),
    onDefiningMarkingsChange: (definingMarkings: string) => send('INPUT_DEFINING_MARKINGS', { definingMarkings }),
    onCareInformationChange: (careInformation: string) => send('INPUT_CARE_INFORMATION', { careInformation }),
    ...state.context,
  };

  return (
    <EditDogLayout>
      <Grid
        container
        spacing={4}
      >
        <Grid item lg="auto">
          <DogProfilePicture { ...dogProfilePictureProps } />
        </Grid>
        <Grid item lg={6}>
          <DogInformationForm { ...dogInfoProps } />
        </Grid>
      </Grid>
    </EditDogLayout>
  );
}
