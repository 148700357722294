import React, { useState } from 'react';

import { useNavigate, Navigate } from "react-router-dom";
import { DateTime } from 'luxon';
import { useMachine } from '@xstate/react';

import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { DogInformationForm, DogInformationFormProps } from '../forms/dogInformation';
import { DogSex, Weight } from '../enums';
import { CreateLayout } from './components/creation-layout';
import { createDogMachine } from '../machines/createDog';

export const CreateDog: React.FC = () => {
  const navigate = useNavigate();

  const [ state, send ] = useMachine(createDogMachine);

  const dogInfoProps: DogInformationFormProps = {
    onNameChange: (name: string) => send('INPUT_NAME', { name }),
    onBreedChange: (breed: string) => send('INPUT_BREED', { breed }),
    onDateOfBirthChange: (dateOfBirth: DateTime | null) => send('INPUT_DATE_OF_BIRTH', { dateOfBirth }),
    onSexChange: (sex: DogSex) => send('INPUT_SEX', { sex }),
    onWeightValueChange: (value: number) => send('INPUT_WEIGHT', { value }),
    onWeightUnitChange: (unit: Weight) => send('INPUT_WEIGHT_UNIT', { unit }),
    onMicrochippedChange: (microchipped: boolean) => send('INPUT_MICROCHIPPED', { microchipped }),
    onDefiningMarkingsChange: (definingMarkings: string) => send('INPUT_DEFINING_MARKINGS', { definingMarkings }),
    onCareInformationChange: (careInformation: string) => send('INPUT_CARE_INFORMATION', { careInformation }),
    ...state.context,
  };

  const handleClose = () => navigate('/dogs');
  const handleCreate = () => send('CREATE');

  return (
    state.matches('created')
      ? <Navigate to={`/dogs/${state.context.id}/personal`} />
      : <CreateLayout
          onClose={handleClose}
          title={
            <Typography textAlign="center" variant='h4'>Dog Information</Typography>
          }
          form={
            <DogInformationForm {...dogInfoProps} />
          }
          actions={
            <LoadingButton
              variant="contained"
              onClick={ handleCreate }
              loading={ state.matches('creating') }
              disabled={ state.matches('idle') }
            >Create</LoadingButton>
          }
        />
  );
}
