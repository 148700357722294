export  const createDogOwnerContactInformationContext = (): DogOwnerContactInformationContext => ({
  owner: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  }
});

export interface DogOwnerContactInformationContext {
  owner: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
  };
}
